import {
    ButtonGroup,
    Divider,
    Flex,
    Heading,
    HStack,
    Spacer,
    Text,
    VStack,
} from "@chakra-ui/react";
import { EnquiryType } from "../../../../types";
import { useSmartTranslation } from "../../common/hooks/useSmartTranslation";
import ArchiveEnquiry from "./ArchiveEnquiry";
import CancelEnquiry from "./CancelEnquiry";

type Params = {
    enquiry: EnquiryType;
    dormantForUser: boolean;
    userIsClient: boolean;
    onUpdate: () => any;
    onError: (error: Error) => any;
    clientInfo?: React.ReactNode;
};

const Title = (params: Params) => {
    const {
        enquiry: { id, status },
        enquiry,
        dormantForUser,
        userIsClient,
        onUpdate,
        onError,
        clientInfo,
    } = params;

    const t = useSmartTranslation();

    const cancelButton = userIsClient && (
        <CancelEnquiry
            onSuccess={onUpdate}
            onError={onError}
            enquiry={enquiry}
        />
    );

    const archiveButton = (
        <ArchiveEnquiry
            enquiry={enquiry}
            dormantForUser={dormantForUser}
            userIsClient={userIsClient}
            onSuccess={onUpdate}
            onError={onError}
        />
    );

    // render

    const rightSide = (
        <HStack spacing={4}>
            <ButtonGroup variant="outline">
                {cancelButton}
                {archiveButton}
            </ButtonGroup>
            {clientInfo && (
                <>
                    <Divider
                        orientation="vertical"
                        minH="3em"
                        h="100%"
                    />
                    {clientInfo}
                </>
            )}
        </HStack>
    );

    return (
        <Flex
            bg="white"
            w="100%"
            align="center"
            padding=".5em 1.5em"
            borderRadius="8"
        >
            <VStack
                align="start"
                spacing="0"
            >
                <Heading
                    color="blue.700"
                    size="lg"
                >
                    {t("domain.enquiry.enquiryId", { id })}
                </Heading>
                <HStack>
                    <Text color="darkgrey">
                        {t("domain.enquiry.statusLabel")}
                    </Text>
                    <Text
                        color="blue.400"
                        fontWeight="bold"
                    >
                        {t(`domain.enquiry.status.${status}`)}
                    </Text>
                </HStack>
            </VStack>
            <Spacer />
            {rightSide}
        </Flex>
    );
};

export default Title;
