import { VStack, Flex, Spacer, Box } from "@chakra-ui/react";
import {
    FormikInput,
    FormikSelect,
    FormikCurrencyInput,
} from "../../../../common/forms";
import { useSmartTranslation } from "../../../../common/hooks";
import { WvwText } from "../../../../common/components/typography";
import standardPropertyConditions from "../../../../common/vars/valuationsAndMembershipTypes/standardPropertyConditions";

type PropTypes = {
    isError: boolean;
};

const PropertyCondition = (props: PropTypes) => {
    const t = useSmartTranslation("enquiryform");

    const { isError } = props;

    return (
        <VStack
            w="100%"
            align="left"
            spacing="1rem"
        >
            <Flex
                w="100%"
                flexWrap="wrap"
            >
                <Box
                    width="100%"
                    maxWidth={{ md: "49%" }}
                >
                    <FormikInput
                        type="number"
                        label={t(
                            "newEnquiry.addingProperty.propertyDescription.buildYear"
                        )}
                        name="buildYear"
                        placeholder={t("fieldPlaceholder.year", {
                            ns: "common",
                        })}
                    />
                </Box>

                <Spacer />

                <Box
                    width="100%"
                    maxWidth={{ md: "49%" }}
                >
                    <FormikSelect
                        name="propertyCondition"
                        label={t(
                            "newEnquiry.addingProperty.propertyDescription.condition"
                        )}
                        placeholder={t("fieldPlaceholder.pleaseSelect", {
                            ns: "common",
                        })}
                        options={standardPropertyConditions.map(
                            (condition) => ({
                                label: t(condition.key, { ns: "formik" }),
                                value: condition.value,
                            })
                        )}
                    />
                </Box>
            </Flex>

            {isError && (
                <WvwText color="red">
                    {t("provideField.select.atLeastOne.propertyDescription", {
                        ns: "common",
                    })}
                </WvwText>
            )}

            <Flex
                w="100%"
                gap={1}
                flexWrap="wrap"
            >
                <Box
                    width="100%"
                    maxWidth={{ md: "32%" }}
                >
                    <FormikCurrencyInput
                        label={t("propertyDetails.grossInternalArea", {
                            ns: "enquiries",
                        })}
                        name="areaGrossInternal"
                        prefix={t("unitSymbol.squareMeasure", { ns: "common" })}
                    />
                </Box>

                <Spacer />

                <Box
                    width="100%"
                    maxWidth={{ md: "32%" }}
                >
                    <FormikCurrencyInput
                        name="areaOverall"
                        label={t(
                            "newEnquiry.addingProperty.propertyDescription.features.overallArea"
                        )}
                        prefix={t("unitSymbol.squareMeasure", { ns: "common" })}
                    />
                </Box>

                <Spacer />

                <Box
                    width="100%"
                    maxWidth={{ md: "32%" }}
                >
                    <FormikCurrencyInput
                        name="estimateValue"
                        label={t(
                            "newEnquiry.addingProperty.propertyDescription.features.estimatedValue"
                        )}
                        prefix={t("unitSymbol.currency", { ns: "common" })}
                    />
                </Box>
            </Flex>

            {/* <FormikConditionalSection
                formDataTarget="propertyTypes"
                condition={value => !value.includes('Development')}
            >
                <Flex w="100%">
                    <Box
                        w="100%"
                        alignSelf="flex-end"
                    >
                        <FormikCurrencyInput
                            name="estimateValue"
                label={t('newEnquiry.addingProperty.propertyDescription.features.estimatedValue')}
                            prefix={t('unitSymbol.currency', { ns: 'common' })}
                        />
                    </Box>
                </Flex>
            </FormikConditionalSection>

            <FormikConditionalSection
                formDataTarget="propertyTypes"
                condition={value => value.includes('Development')}
            >
                <Flex w="100%">
                    <Box
                        w= xs: '100%', lg: "49%"
                        alignSelf="flex-end"
                    >
                        <FormikCurrencyInput
                            name="estimateValue"
                label={t('newEnquiry.addingProperty.propertyDescription.features.estimatedValue')}
                            prefix={t('unitSymbol.currency', { ns: 'common' })}
                        />
                    </Box>

                    <Spacer />

                    <Box w= xs: '100%', lg: "49%">
                        <FormikCurrencyInput
                            name="estimateGVD"
                label={t('newEnquiry.addingProperty.propertyDescription.features.estimatedGDV')}
                            prefix={t('unitSymbol.currency', { ns: 'common' })}
                        />
                    </Box>
                </Flex>
            </FormikConditionalSection> */}
        </VStack>
    );
};

export default PropertyCondition;
