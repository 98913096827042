import { get } from "../../../../../utils/api/request";
import { EnquiryMatchInfo } from "./EnquiryMatchInfo";

const matchEnquiryWithValuer = (
    enquiryId: number,
    valuerId: number
): Promise<EnquiryMatchInfo> =>
    get(`/enquiry/${enquiryId}/matchWithValuer/${valuerId}`);

export default matchEnquiryWithValuer;
