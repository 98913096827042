import { ParkingType } from "../../../../../common/vars/valuationsAndMembershipTypes/parkingTypes";

type PropertyFeatureType = ParkingType;

type TranslationNSObject = {
    [key in PropertyFeatureType]: string;
};

type Translations = {
    de: TranslationNSObject;
    en: TranslationNSObject;
};

export const propertyFeatureTranslations: Translations = {
    de: {
        Carport: "Überdachter Stellplatz",
        "Double Garage - Deluxe": "Doppelgarage - Deluxe",
        "Double Garage - Normal": "Doppelgarage - Normal",
        "Single Garage - Deluxe": "Einzelgarage - Deluxe",
        "Single Garage - Normal": "Einzelgarage - Normal",
        "Parking - Deep": "Parken - Tief",
        "Parking - High": "Parken - Hoch",
        "Parking - Outdoor": "Parken - im Freien",
    },
    en: {
        Carport: "Carport",
        "Double Garage - Deluxe": "Double Garage - Deluxe",
        "Double Garage - Normal": "Double Garage - Normal",
        "Single Garage - Deluxe": "Single Garage - Deluxe",
        "Single Garage - Normal": "Single Garage - Normal",
        "Parking - Deep": "Parking - Deep",
        "Parking - High": "Parking - High",
        "Parking - Outdoor": "Parking - Outdoor",
    },
};
