import ArchiveEnquiry from "./ArchiveEnquiry";
import CancelEnquiry from "./CancelEnquiry";
import {
    determineStandard,
    determineType,
    getDeliveryDate
} from "./enquiryFunctions";
import { EnquiryRequirements } from "./enquiryRequirements/EnquiryRequirements";
import { translations } from "./i18n";
import Title from "./Title";
import { ValuationPurposes } from "./ValuationPurposes";

export const Enquiry = {
    Title,
    Requirements: EnquiryRequirements,
    translations,
    getDeliveryDate,
    archive: ArchiveEnquiry,
    cancel: CancelEnquiry,
    determineStandard,
    determineType,
};

export const valuationPurposes = {
    ValuationPurposes,
};
