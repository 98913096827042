import { LandPropertyType } from "../../../../../common/vars/valuationsAndMembershipTypes/landPropertyTypes";

type TranslationNSObject = {
    [key in LandPropertyType]: string;
};

type Translations = {
    de: TranslationNSObject;
    en: TranslationNSObject;
};

export const landPropertyTypeTranslations: Translations = {
    de: {
        "No Planning": "Keine Planung",
        "Other Land": "Sonstiges Land",
        "With Planning - Agricultural": "Mit Planung - Landwirtschaft",
        "With Planning - Commercial": "Mit Planung - Gewerblich",
        "With Planning - Mixed": "Mit Planung - Gemischt",
        "With Planning - Residential": "Mit Planung - Wohnwirtschaftlich",
    },
    en: {
        "No Planning": "No Planning",
        "Other Land": "Other Land",
        "With Planning - Agricultural": "With Planning - Agricultural",
        "With Planning - Commercial": "With Planning - Commercial",
        "With Planning - Mixed": "With Planning - Mixed",
        "With Planning - Residential": "With Planning - Residential",
    },
};
