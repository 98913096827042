export type LandPropertyType = (typeof landPropertyTypes)[number];

const landPropertyTypes = [
    "With Planning - Commercial",
    "With Planning - Agricultural",
    "With Planning - Mixed",
    "With Planning - Residential",
    "No Planning",
    "Other Land",
] as const;

export default landPropertyTypes;
