import { useMutation, useQuery, useQueryClient } from 'react-query';
import { EnquiryUpdateDto } from '../dto';
import { enquiryGetById } from '../endpoints';
import enquiryUpdate from '../endpoints/enquiry-update';

type ParamsType = {
    enquiryId: string | undefined;
    onUpdateSuccess?: () => void;
    onUpdateError?: (error: Error) => void;
};

const ENQUIRY_BY_ID = 'ENQUIRY_BY_ID';

const useEnquiry = (params: ParamsType) => {
    const {
        enquiryId,
        onUpdateSuccess,
        onUpdateError,
    } = params;

    const queryClient = useQueryClient();

    const {
        data: enquiry,
        error,
        isLoading,
    } = useQuery(
        [ENQUIRY_BY_ID, enquiryId],
        () => enquiryGetById(enquiryId as string),
        { enabled: !!enquiryId },
    );

    const refresh = () => queryClient.invalidateQueries(ENQUIRY_BY_ID);

    const { mutate: update } = useMutation(
        (dto: EnquiryUpdateDto) => enquiryUpdate(dto),
        {
            onSuccess: () => {
                refresh();

                onUpdateSuccess?.();
            },
            onError: onUpdateError,
        },
    );

    return {
        enquiry,
        error,
        isLoading,
        refresh,
        update,
    };
};

export default useEnquiry;
