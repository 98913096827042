import { StandardMinimumPartners } from "../../../../../common/vars/valuationsAndMembershipTypes/standardMinimumPartners";

type TranslationNSObject = {
    [key in StandardMinimumPartners]: string;
};

type Translations = {
    de: TranslationNSObject;
    en: TranslationNSObject;
};

export const minPartnersTranslations: Translations = {
    de: {
        "0": "Keine Angabe",
        "1": "Einzelunternehmer",
        "2": "Zwei Partner",
        "3": "Drei Partner",
        "4": "Vier oder mehr Partner",
    },
    en: {
        "0": "No Requirement",
        "1": "Single Partner",
        "2": "Two Partners",
        "3": "Three Partners",
        "4": "Four or More Partners",
    },
};
