import { Button, HStack, Text } from "@chakra-ui/react";
import { useState } from "react";
import Terms from "./public/legal/Terms";
import Privacy from "./public/legal/Privacy";
import Imprint from "./public/legal/Imprint";
import { useSmartTranslation } from "../common/hooks/useSmartTranslation";
import useSupportModal from "../../../utils/api/hooks/useSupportModal";
import ModalDynamic from "../common/components/ModalDynamic";
import { ThemeV1Wrapper } from "../app/ThemeV1Wrapper";

type PropTypes = {
    invertColor?: boolean;
};

const Footer = (props: PropTypes) => {
    const { invertColor } = props;

    const { modal: supportModal, openModal } = useSupportModal();

    const t = useSmartTranslation();

    const [modal, setModal] = useState<
        "terms" | "privacy" | "imprint" | undefined
    >();

    const textColor = invertColor ? "white" : "black";

    const modalHeader = {
        terms: t("legal.termsHeader"),
        imprint: t("legal.imprintExtended"),
        privacy: t("legal.privacyHeader"),
    };

    const modalBody = {
        terms: <Terms />,
        imprint: <Imprint />,
        privacy: <Privacy />,
    };

    const footerButton = (modalToOpen: any, text: string, divider: boolean) => (
        <HStack>
            <Button
                color={textColor}
                fontSize="0.8rem"
                fontWeight="normal"
                onClick={() => modalToOpen()}
                variant="link"
            >
                <Text color={textColor}>{text}</Text>
            </Button>

            {divider && <Text color={textColor}>{" | "}</Text>}
        </HStack>
    );

    return (
        <>
            <HStack
                fontSize="0.8rem"
                justifyContent="center"
                paddingBlock="0.5rem"
                w="100%"
                flexWrap="wrap"
            >
                {footerButton(
                    openModal,
                    t("screen.footer.reportAProblem"),
                    true
                )}
                {footerButton(
                    () => setModal("privacy"),
                    t("legal.privacy"),
                    true
                )}
                {footerButton(() => setModal("terms"), t("legal.terms"), true)}
                {footerButton(
                    () => setModal("imprint"),
                    t("legal.imprint"),
                    false
                )}
            </HStack>

            {supportModal}

            <ThemeV1Wrapper>
                <ModalDynamic
                    variant="small"
                    isOpen={!!modal}
                    header={
                        modalHeader[modal as "terms" | "privacy" | "imprint"]
                    }
                    onClose={() => setModal(undefined)}
                >
                    {modalBody[modal as "terms" | "privacy" | "imprint"]}
                </ModalDynamic>
            </ThemeV1Wrapper>
        </>
    );
};

Footer.defaultProps = {
    invertColor: false,
};

export default Footer;
