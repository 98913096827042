export type OfferStatus = (typeof offerStatus)[number];

type TranslationNSObject = {
    [key in OfferStatus]: string;
};

type Translations = {
    de: TranslationNSObject;
    en: TranslationNSObject;
};

const offerStatus = ["ACCEPTED", "PENDING", "REJECTED", "WITHDRAWN"] as const;

export const offerStatusTranslations: Translations = {
    de: {
        ACCEPTED: "ANGENOMMEN",
        PENDING: "SCHWEBEND",
        REJECTED: "ABGELEHNT",
        WITHDRAWN: "ZURÜCKGENOMMEN",
    },
    en: {
        ACCEPTED: "ACCEPTED",
        PENDING: "PENDING",
        REJECTED: "REJECTED",
        WITHDRAWN: "WITHDRAWN",
    },
};
