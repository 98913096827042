export type DevelopmentPropertyType = (typeof developmentPropertyTypes)[number];

const developmentPropertyTypes = [
    "Commercial",
    "Rural",
    "Specialist and Trade",
    "Residential",
    "Other Development",
] as const;

export default developmentPropertyTypes;
