import {
    Divider,
    Text,
    Heading,
    HStack,
    VStack,
    Spacer,
} from "@chakra-ui/react";
import {
    TranslationFunction,
    useSmartTranslation,
} from "../../hooks/useSmartTranslation";

export type StatsType = {
    title: (t: TranslationFunction) => string;
    data: string | number | undefined;
    key: string;
};

type StatisticsProps = {
    stats: StatsType[];
};

const Statistics = (props: StatisticsProps) => {
    const { stats } = props;

    const children = stats.map((stat) => {
        const { title, data, key } = stat;
        return (
            <Statistic
                title={title}
                data={data}
                key={key}
            />
        );
    });

    return (
        <HStack
            divider={<Divider orientation="vertical" />}
            height="100%"
            gap="1rem"
            paddingRight="1rem"
        >
            {children}
        </HStack>
    );
};

// helpers

const Statistic = (props: StatsType) => {
    const { title, data } = props;
    const t = useSmartTranslation();
    let dataColor = "gray.700";
    let dataContent = data;

    if (data === undefined) {
        dataColor = "gray.400";
        dataContent = t("common.n/a");
    }

    return (
        <VStack
            spacing={0}
            w="100%"
            alignItems="flex-start"
            height="100%"
        >
            <Text
                fontSize="14px"
                color="gray.500"
                fontWeight="bold"
            >
                {title(t)}
            </Text>
            <Spacer />
            <Heading color={dataColor}>{dataContent}</Heading>
        </VStack>
    );
};

export default Statistics;
