import { SimpleGrid, VStack } from "@chakra-ui/layout";
import { useBreakpointValue } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useRole, useToast } from "../../../../../../common/hooks";
import { EnquiryType, PropertyType } from "../../../../../../types";
import {
    useEnquiry,
    useEnquiryGetDormant,
} from "../../../../../../utils/api/hooks";
import LoadingSpinner from "../../../../common/components/LoadingSpinner";
import { useSmartTranslation } from "../../../../common/hooks/useSmartTranslation";
import { Property, User } from "../../../../domain";
import { EnquiryMatchInfo } from "../../../../domain/enquiry/enquiryRequirements/EnquiryMatchInfo";
import { EnquiryRequirements } from "../../../../domain/enquiry/enquiryRequirements/EnquiryRequirements";
import matchEnquiryWithValuer from "../../../../domain/enquiry/enquiryRequirements/matchEnquiryWithValuer";
import { testOffers } from "../../../../domain/offer/testdata";
import { Section } from "../../../../layout";
import { CompanyOffers } from "./CompanyOffers";
import { ValuerEnquiryTitle } from "./EnquiryTitle";
import { MyOffer } from "./MyOffer";
import { PropertiesMap } from "./PropertiesMap";
import { PropertyPortfolioSummary } from "./PropertyPortfolioSummary";
import { ValuerPropertyDetails } from "./ValuerPropertyDetails";

const translationNS = "screens.platform.valuer.valuerEnquiryDetails";

type EnquiryDetailsProps = {
    user: User;
};

const EnquiryDetails = (props: EnquiryDetailsProps) => {
    const { user } = props;

    const { createErrorToast } = useToast();

    const { enquiryId } = useParams();

    const { roleLoading, userIsClient } = useRole();

    const {
        enquiry,
        refresh: refreshEnquiry,
        isLoading: enquiryLoading,
    } = useEnquiry({ enquiryId });

    const {
        data: dormantEnquiries,
        isLoading: loadingDormancy,
        refresh: refreshDormancy,
    } = useEnquiryGetDormant();

    const [matchInfo, setMatchinfo] = useState<EnquiryMatchInfo | undefined>();

    useEffect(() => {
        if (enquiry) {
            const getMatchInfo = async () => {
                const matchInfo = await matchEnquiryWithValuer(
                    enquiry.id,
                    user.id
                );
                setMatchinfo(matchInfo);
            };

            try {
                getMatchInfo();
            } catch (e) {
                console.log(`Problem when retrieving matchinfo: ${e}`);
            }
        }
    }, [enquiry]);

    if (roleLoading || enquiryLoading || !dormantEnquiries || loadingDormancy)
        return <LoadingSpinner />;

    // actually: if there's no enquiry we should show a 404 or so
    if (!enquiry || !matchInfo) return <LoadingSpinner />;

    const onUpdate = () => {
        refreshEnquiry();
        refreshDormancy();
    };
    const onError = (error: Error) => createErrorToast(error.message);

    const dormantForUser = dormantEnquiries
        .map((enq) => enq.id)
        .includes(enquiry.id);

    return (
        <EnquiryDetailsScreen
            enquiry={enquiry}
            dormantForUser={dormantForUser}
            userIsClient={userIsClient}
            matchInfo={matchInfo}
            onUpdate={onUpdate}
            onError={onError}
        />
    );
};

type EnquiryDetailsScreenProps = {
    enquiry: EnquiryType;
    dormantForUser: boolean;
    userIsClient: boolean;
    matchInfo: EnquiryMatchInfo;
    onUpdate: () => any;
    onError: (error: Error) => any;
};

const EnquiryDetailsScreen = (props: EnquiryDetailsScreenProps) => {
    const {
        matchInfo,
        enquiry,
        dormantForUser,
        userIsClient,
        onUpdate,
        onError,
    } = props;

    const device = useBreakpointValue(
        { base: "tablet", "2xl": "desktop" },
        { ssr: false }
    );

    const t = useSmartTranslation();

    // enquiry

    const [selectedProperty, setSelectedProperty] = useState<PropertyType>(
        enquiry.properties[0]
    );

    // enquiry
    const title = (
        <ValuerEnquiryTitle
            enquiry={enquiry}
            dormantForUser={dormantForUser}
            userIsClient={userIsClient}
            onUpdate={onUpdate}
            onError={onError}
        />
    );

    const requestedValuation = (
        <Section
            title={t("domain.enquiry.requestedValuationTitle")}
            content={
                <EnquiryRequirements
                    enquiry={enquiry}
                    matchInfo={matchInfo}
                />
            }
        />
    );

    const propertyPortfolio = (
        <Section
            title={t(`${translationNS}.propertyPortfolio.title`)}
            content={
                <VStack
                    w="100%"
                    spacing="4"
                >
                    <PropertyPortfolioSummary properties={enquiry.properties} />
                    <Property.PortfolioTable
                        properties={enquiry.properties}
                        selectedProperty={selectedProperty}
                        onRowClick={(item) => {
                            setSelectedProperty(item);
                        }}
                    />
                </VStack>
            }
        />
    );

    const map = (
        <Section
            content={
                <PropertiesMap
                    properties={enquiry.properties}
                    selectedProperty={selectedProperty}
                />
            }
        />
    );

    const propertyDetails = (
        <Section
            title={`${selectedProperty.street}, ${selectedProperty.postalCode} ${selectedProperty.city}`}
            content={<ValuerPropertyDetails property={selectedProperty} />}
        />
    );

    const offers = testOffers;

    const companyOffers = (
        <Section
            title={t(`${translationNS}.companyOffers.title`, {
                nCompanyOffers: 2,
            })}
            content={<CompanyOffers offers={offers} />}
        />
    );

    const offer = offers[0];

    const myOffer = (
        <Section
            title={t(`${translationNS}.myOffer.title`)}
            content={<MyOffer offer={offer} />}
        />
    );

    // render

    return (
        <VStack
            w={"100%"}
            padding=".5em"
            spacing={4}
        >
            {title}
            <SimpleGrid
                width={"100%"}
                columns={{ base: 2, "2xl": 3 }}
                spacing={2}
                gridTemplateColumns={
                    device === "tablet" ? "55% 1fr" : "40% 1fr 1fr"
                }
            >
                {device === "tablet" && (
                    <>
                        <VStack>
                            {requestedValuation}
                            {propertyPortfolio}
                            {/* TODO: display map with property details in drawer (in another task) */}
                            {map}
                            {propertyDetails}
                        </VStack>
                        <VStack>
                            {companyOffers}
                            {myOffer}
                        </VStack>
                    </>
                )}

                {device === "desktop" && (
                    <>
                        <VStack>
                            {requestedValuation}
                            {propertyPortfolio}
                        </VStack>
                        <VStack>
                            {map}
                            {propertyDetails}
                        </VStack>
                        <VStack>
                            {companyOffers}
                            {myOffer}
                        </VStack>
                    </>
                )}
            </SimpleGrid>
        </VStack>
    );
};

export default EnquiryDetails;
