import {
    BEST_SECURITY,
    CHEAPEST,
    EARLIEST_AVAILABILITY,
    EARLIEST_DELIVERY,
    SHORTEST_TIMELINE
} from "./enrichers/enrichers";

// BEST_SECURITY: "Höchste Haftungssumme" if enough space

export const offerEnrichments = {
    de: {
        [BEST_SECURITY]: "Höchste Haftung",
        [CHEAPEST]: "Günstigstes Angebot",
        [EARLIEST_AVAILABILITY]: "Am schnellsten",
        [EARLIEST_DELIVERY]: "Am schnellsten",
        [SHORTEST_TIMELINE]: "Am schnellsten",
    },
    en: {
        [BEST_SECURITY]: "Best security",
        [CHEAPEST]: "Cheapest offer",
        [EARLIEST_AVAILABILITY]: "Fastest",
        [EARLIEST_DELIVERY]: "Fastest",
        [SHORTEST_TIMELINE]: "Fastest",
    },
};
