import {
    Drawer,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    DrawerHeader,
    DrawerBody,
} from "@chakra-ui/react";
import { wvwPalette } from "../../../../theme/theme-v2";
import WVWLogoWhite from "../../../../../../assets/icons/WVWLogoWhite";

type PropTypes = {
    children: React.ReactElement;
    isOpen: boolean;
    onClose: () => void;
};

const MobiMenuBarDrawer = (props: PropTypes) => {
    const { children, isOpen, onClose } = props;

    return (
        <Drawer
            isOpen={isOpen}
            placement="left"
            onClose={onClose}
            size="xl"
            isFullHeight
        >
            <DrawerOverlay />

            <DrawerContent bgColor={wvwPalette.wvwBackground.menubarMain}>
                <DrawerCloseButton color="white" />
                <DrawerHeader>
                    <WVWLogoWhite width="4rem" />
                </DrawerHeader>

                <DrawerBody padding="0">{children}</DrawerBody>
            </DrawerContent>
        </Drawer>
    );
};

export default MobiMenuBarDrawer;
