import { ChakraProvider } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import theme from "../../../theme";
import { ReactNode } from "react";

type Props = {
    children?: ReactNode;
};

export const ThemeV1Wrapper = ({ children }: Props) => {
    return (
        <ChakraProvider theme={theme}>
            {children == undefined && <Outlet />}

            {children !== undefined && children}
        </ChakraProvider>
    );
};
