import { StandardYearsOfExperience } from "../../../../../common/vars/valuationsAndMembershipTypes/standardYearsOfExperience";

type TranslationNSObject = {
    [key in StandardYearsOfExperience]: string;
};

type Translations = {
    de: TranslationNSObject;
    en: TranslationNSObject;
};

export const yearsOfExperienceTranslations: Translations = {
    de: {
        "0": "Weniger als 1 Jahr",
        "1": "1-3 Jahren",
        "3": "3-5 Jahren",
        "5": "5+ Jahren",
    },
    en: {
        "0": "Less than 1 year",
        "1": "1-3 years",
        "3": "3-5 years",
        "5": "5 years or more",
    },
};
