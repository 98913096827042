export type EnquiryStatus = (typeof enquiryStatus)[number];

type TranslationNSObject = {
    [key in EnquiryStatus]: string;
};

type Translations = {
    de: TranslationNSObject;
    en: TranslationNSObject;
};

export const enquiryStatusTranslations: Translations = {
    de: {
        ACCEPTED: "ANGENOMMEN",
        CANCELLED: "ABGEBROCHEN",
        DRAFT: "ENTWURF",
        PENDING: "SCHWEBEND",
    },
    en: {
        ACCEPTED: "ACCEPTED",
        CANCELLED: "CANCELLED",
        DRAFT: "DRAFT",
        PENDING: "PENDING",
    },
};

const enquiryStatus = ["ACCEPTED", "CANCELLED", "DRAFT", "PENDING"] as const;
