import { useDisclosure } from "@chakra-ui/react";
import { OfferType } from "../../../../types";
import { OfferRespondDto } from "../../../../utils/api/dto";
import { offerRespond } from "../../../../utils/api/endpoints";
import useMutationHook from "../../../../utils/api/hooks/useMutationHook";
import ConfirmationModal from "../../common/components/ConfirmationModal";
import { useSmartTranslation } from "../../common/hooks/useSmartTranslation";

type ParamsType = {
    onSuccess?: (data?: any) => void;
    onError?: (error: Error) => void;
    refresh?: () => void;
    offers: OfferType[];
};

const useRejectMultipleOffers = (params: ParamsType) => {
    const { offers, onSuccess, refresh, onError } = params;

    const t = useSmartTranslation();

    const { isOpen, onOpen, onClose } = useDisclosure();

    const { update: rejectOffer } = useMutationHook({
        mutationFunction: (dto: OfferRespondDto) => offerRespond(dto),
        refreshes: refresh ? [refresh] : [],
        onSuccess,
        onError,
    });

    const onConfirmRejection = () => {
        onClose();

        for (const offer of offers)
            rejectOffer({
                offerId: offer.id,
                status: "REJECTED",
            });
    };

    const confirmationModal = (
        <ConfirmationModal
            isOpen={isOpen}
            onConfirmation={onConfirmRejection}
            onClose={onClose}
            title={t("domain.offer.ACTIVITIES.rejectAll.NAME")}
            blurb={t("domain.offer.ACTIVITIES.rejectAll.confirmationBlurb")}
            confirmationButtonLabel={t(
                "domain.offer.ACTIVITIES.rejectAll.NAME"
            )}
            confirmationButtonVariant={"secondary"}
        />
    );

    return {
        rejectOffers: onOpen,
        confirmationModal,
    };
};

export default useRejectMultipleOffers;
