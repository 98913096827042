export type StandardRequiredYearsOfExperience =
    (typeof standardRequiredYearsOfExperience)[number]["value"];

const standardRequiredYearsOfExperience = [
    { label: "No Requirement", value: "0" },
    { label: "1-3 years", value: "1" },
    { label: "3-5 years", value: "3" },
    { label: "5+ years", value: "5" },
] as const;

export default standardRequiredYearsOfExperience;
