export type ParkingType = (typeof parkingTypes)[number];

const parkingTypes = [
    "Carport",
    "Single Garage - Normal",
    "Double Garage - Normal",
    "Single Garage - Deluxe",
    "Double Garage - Deluxe",
    "Parking - Deep",
    "Parking - High",
    "Parking - Outdoor",
] as const;

export default parkingTypes;
