export type ResidentialPropertyOtherType =
    (typeof residentialPropertyOtherTypes)[number];

const residentialPropertyOtherTypes = [
    "Farm",
    "Holiday Let",
    "Semi-Commercial",
    "Other",
] as const;

export default residentialPropertyOtherTypes;
