export type StandardMinimumPartners =
    (typeof standardMinimumPartners)[number]["value"];

const standardMinimumPartners = [
    { label: "No Requirement", value: "0" },
    { label: "Single Partner", value: "1" },
    { label: "Two Partners", value: "2" },
    { label: "Three Partners", value: "3" },
    { label: "Four or More Partners", value: "4" },
] as const;

export default standardMinimumPartners;
