import { AwaitingAccess } from "../../ui/v2/common/features/awaiting-access/AwaitingAccess";
import { LanguageToggle } from "../../ui/v2/common/features/languageToggle/LanguageToggle";
import { Notifications } from "../../ui/v2/common/features/notifications/Notifications";
import { Forms } from "../../ui/v2/common/forms/Forms";
import { common } from "../../ui/v2/common/i18n";
import { FileUpload } from "../../ui/v2/common/inputs/fileupload/FileUpload";
import {
    Case,
    Enquiry,
    Offer,
    Property,
    Standards,
    User,
} from "../../ui/v2/domain";
import { screen } from "../../ui/v2/screens/i18n";
import { ClientDashboardScreen } from "../../ui/v2/screens/platform/client/dashboard/Index";
import { EnquiryDetailsScreen as ClientEnquiryDetailsScreen } from "../../ui/v2/screens/platform/client/enquiry-details/Index";
import { MenuBar } from "../../ui/v2/screens/platform/screen/menu-bar";
import { ValuerDashboardScreen } from "../../ui/v2/screens/platform/valuer/dashboard";
import { EnquiryDetailsScreen as ValuerEnquiryDetailsScreen } from "../../ui/v2/screens/platform/valuer/enquiry-details";
import { PasswordReset } from "../../ui/v2/screens/public/forgot-password/PasswordReset";
import { legal } from "../../ui/v2/screens/public/legal/i18n";
import { Login } from "../../ui/v2/screens/public/login";
import { Registration } from "../../ui/v2/screens/public/register";
import { SuccessScreen } from "../../ui/v2/screens/success";
import { V2EnNamespaceType } from "../en/V2EnNamespaceType";

const v2De: V2EnNamespaceType = {
    common: common.de,
    domain: {
        case: Case.translations.de,
        client: User.translations.de,
        enquiry: Enquiry.translations.de,
        offer: Offer.translations.de,
        property: Property.translations.de,
        standards: Standards.translations.de,
    },
    screens: {
        platform: {
            valuer: {
                valuerEnquiryDetails:
                    ValuerEnquiryDetailsScreen.translations.de,
            },
        },
    },
    forms: Forms.translations.de,
    awaitingAccessBanner: AwaitingAccess.translations.de,
    languageToggle: LanguageToggle.translations.de,
    menubar: MenuBar.translations.de,
    notifications: Notifications.translations.de,
    screen: screen.de,
    legal: legal.de,
    login: Login.translations.de,
    fileUpload: FileUpload.translations.de,
    passwordReset: PasswordReset.translations.de,
    registration: Registration.translations.de,
    successScreen: SuccessScreen.translations.de,
    clientEnquiryDetails: ClientEnquiryDetailsScreen.translations.de,
    clientDashboard: ClientDashboardScreen.translations.de,
    valuerDashboard: ValuerDashboardScreen.translations.de,
} as const;

export default v2De;
