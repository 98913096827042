import { Center, Img, VStack } from "@chakra-ui/react";
import { Text } from "@chakra-ui/react";
import { useSmartTranslation } from "../../common/hooks/useSmartTranslation";

const NoOffersView = () => {
    const t = useSmartTranslation();

    return (
        <Center
            height="100%"
        >
            <VStack
                spacing={4}
                align="center"
                justify="center"
                w="100%"
            >
                <Img
                    src="/images/WaitingForOffersIcon.svg"
                    alt="awaiting-offers"
                />

                <Text fontWeight="bold">
                    {t("domain.offer.awaitingOffers.waitingForOffers")}
                </Text>

                <VStack
                    spacing={0}
                    align="center"
                    justify="center"
                >
                    <Text variant="grey">
                        {t("domain.offer.awaitingOffers.waitingForOffersBlurb1")}
                    </Text>
                    <Text variant="grey">

                        {t("domain.offer.awaitingOffers.waitingForOffersBlurb2")}
                    </Text>
                </VStack>
            </VStack>
        </Center>
    );
};

export { NoOffersView };