import { CloseIcon } from "@chakra-ui/icons";
import {
    Box,
    Button,
    HStack,
    Icon,
    Text,
    Tooltip,
    VStack
} from "@chakra-ui/react";
import { ArrowRight2 } from "iconsax-react";
import { ReactNode } from "react";
import { FiCheck, FiMessageSquare, FiTrash } from "react-icons/fi";
import { Link as ReactLink } from "react-router-dom";
import { displayDateWithCurrentLocale } from "../../../../../../../../common/functions/displayDateInLocale";
import { OfferOnlyType, OfferType } from "../../../../../../../../types";
import { useOfferGetByEnquiryId } from "../../../../../../../../utils/api/hooks";
import useRejectOffer from "../../../../../../domain/offer/useRejectOffer";
import { DataTableColumnType } from "../../../../../components/data-table/DataTable";
import PublicUserProfilePictureWithEntityLogo from "../../../../../components/PublicUserProfilePictureWithEntityLogo";
import {
    TranslationFunction,
    useSmartTranslation
} from "../../../../../hooks/useSmartTranslation";
import { MISSING_TRANSLATION } from "../../../../../MISSING_TRANSLATION";
import { EnrichedDataType } from "../../../enricher";

type Column = DataTableColumnType<EnrichedDataType<OfferType>>;

const createRenderFunction = (
    dataName: keyof OfferOnlyType,
    columnDataName: (data: number | string | undefined) => ReactNode
) => {
    return (o: EnrichedDataType<OfferOnlyType>) => {
        const { data: offer, highlights } = o;

        const color = highlights[dataName] ? "green.400" : "";
        const fontWeight = highlights[dataName] ? "bold" : "";
        const columnData = columnDataName(offer[dataName]);

        const t = useSmartTranslation();

        return (
            <VStack spacing="0">
                <Text
                    textAlign="right"
                    color={color}
                    fontWeight={fontWeight}
                >
                    {columnData || "-"}
                </Text>
                {highlights[dataName] &&
                    highlights[dataName].map((highlight) => (
                        <Text
                            key={highlight.keyword}
                            textAlign="right"
                            variant="secondary"
                            fontSize="12px"
                        >
                            {t(
                                "common.features.enrichment.domain.offer." +
                                    highlight.keyword,
                                { defaultValue: MISSING_TRANSLATION }
                            )}
                        </Text>
                    ))}
            </VStack>
        );
    };
};

export const valuerProfile: Column = {
    alignment: "left",
    title: (t) => t("common.components.dataTable.columns.offerColumns.valuer"),
    render: (o: EnrichedDataType<OfferType>) => {
        const { data: offer } = o;

        return (
            <HStack gap="16px">
                <PublicUserProfilePictureWithEntityLogo
                    entityId={offer.fromEntityId}
                    userId={offer.fromValuerId}
                />
                <VStack
                    alignItems="flex-start"
                    spacing="0"
                >
                    <Text fontWeight="bold">
                        {offer.fromValuer.firstName} {offer.fromValuer.lastName}
                    </Text>
                    <Text color="gray.500">
                        {offer.fromValuer.workingForCompanyName}
                    </Text>
                </VStack>
            </HStack>
        );
    },
};

export const commment: Column = {
    alignment: "right",
    render: (o: EnrichedDataType<OfferType>) => {
        // Used can click on this to view comments
        const {
            data: { comments },
        } = o;

        const t = useSmartTranslation();

        return (
            comments && (
                <Tooltip
                    label={t(
                        "common.components.dataTable.columns.offerColumns.hasCommentTooltip"
                    )}
                >
                    <span>
                        <Icon
                            as={FiMessageSquare}
                            boxSize="24px"
                            color="gray.400"
                        />
                    </span>
                </Tooltip>
            )
        );
    },
};

export const piCoverValue: Column = {
    alignment: "right",
    title: (t) =>
        t("common.components.dataTable.columns.offerColumns.piCoverValue"),
    render: createRenderFunction(
        "piCoverValue",
        (piCoverValue) => piCoverValue && `€${piCoverValue}`
    ),
};

export const piCoverPercentage: Column = {
    alignment: "right",
    title: (t) => "[%]",
    render: createRenderFunction(
        "piCoverPercentage",
        (piCoverPercentage) => piCoverPercentage && `${piCoverPercentage}%`
    ),
};

export const specialPi: Column = {
    alignment: "right",
    title: (t) =>
        t("common.components.dataTable.columns.offerColumns.specialPi"),
    render: (offer: EnrichedDataType<OfferType>) => {
        const specialPiIcon = offer.data.fromEntity
            .piCoverSpecialArrangementsAvailable ? (
            <FiCheck
                color="green"
                size="24px"
            />
        ) : (
            <CloseIcon
                boxSize="10px"
                color="gray.400"
            />
        );

        return <Box>{specialPiIcon}</Box>;
    },
};

export const earliestAvailability: Column = {
    alignment: "right",
    title: (t) =>
        t(
            "common.components.dataTable.columns.offerColumns.earliestAvailability"
        ),
    render: createRenderFunction("availability", (availability) => {
        if (!availability) return null;
        const availabilityDate = new Date(availability);
        return displayDateWithCurrentLocale(availabilityDate);
    }),
};

export const workingTime: Column = {
    alignment: "right",
    title: (t) =>
        t("common.components.dataTable.columns.offerColumns.workingTime"),
    render: (o: EnrichedDataType<OfferType>, t: TranslationFunction) =>
        createRenderFunction(
            "timeline",
            (timeline) =>
                timeline &&
                `${timeline} ${t(
                    "common.components.dataTable.columns.offerColumns.days"
                )}`
        )(o),
};

export const EARLIEST_DELIVERY_FIELDNAME = "earliestDelivery";

// TODO refactor createRenderFunction to be able to be used here
export const delivery: Column = {
    alignment: "right",
    title: (t) =>
        t("common.components.dataTable.columns.offerColumns.delivery"),
    render: (enrichedOffer: EnrichedDataType<OfferType>) => {
        const {
            highlights,
            data: { availability, timeline },
        } = enrichedOffer;

        const t = useSmartTranslation();

        // basically C&P from createRenderFunction
        const dataName = EARLIEST_DELIVERY_FIELDNAME;

        const color = highlights[dataName] ? "green.400" : "";
        const fontWeight = highlights[dataName] ? "bold" : "";

        const earliestAvailability = new Date(availability);
        const currentDays = earliestAvailability.getDate();
        const newDays = currentDays + Number(timeline);

        earliestAvailability.setDate(newDays);

        const columnData = displayDateWithCurrentLocale(earliestAvailability);

        return (
            <VStack spacing="0">
                <Text
                    textAlign="right"
                    color={color}
                    fontWeight={fontWeight}
                >
                    {columnData || "-"}
                </Text>
                {highlights[dataName] &&
                    highlights[dataName].map((highlight) => (
                        <Text
                            key={highlight.keyword}
                            textAlign="right"
                            variant="secondary"
                            fontSize="12px"
                        >
                            {t(
                                "common.features.enrichment.domain.offer." +
                                    highlight.keyword,
                                { defaultValue: MISSING_TRANSLATION }
                            )}
                        </Text>
                    ))}
            </VStack>
        );
    },
};

export const price: Column = {
    alignment: "right",
    title: (t) => t("common.components.dataTable.columns.offerColumns.price"),
    render: createRenderFunction(
        "price",
        (price) => price != undefined && <strong>{`€${price}`}</strong>
    ),
};

export const view: Column = {
    render: (o: EnrichedDataType<OfferType>, t: TranslationFunction) => {
        return (
            <Button
                size="sm"
                as={ReactLink}
                to={`/dashboard/enquiry/${o.data.enquiryId}/offer/${o.data.id}`}
                variant="primary"
                children={t(
                    "common.components.dataTable.columns.offerColumns.check"
                )}
                rightIcon={<ArrowRight2 size="16" />}
            />
        );
    },
};

export const deleteOffer: Column = {
    render: (o: EnrichedDataType<OfferType>) => {
        const { data: offer } = o;

        const {
            refresh: refreshOffers,
        } = useOfferGetByEnquiryId(Number(offer.enquiryId));

        const { rejectOffer, confirmationModal } = useRejectOffer({
            offer,
            refresh: refreshOffers,
        });

        return (
            <Button
                variant="outline"
                height="32px"
                onClick={() => rejectOffer()}
                borderColor="red"
                iconSpacing="0"
                rightIcon={
                    <FiTrash
                        color="red"
                        size="16px"
                    />
                }
                >
                {confirmationModal}
                </Button>
        );
    },
};

export const enrichedOfferColumns = {
    valuerProfile,
    commment,
    piCoverValue,
    piCoverPercentage,
    specialPi,
    earliestAvailability,
    workingTime,
    delivery,
    price,
    view,
    deleteOffer,
};
