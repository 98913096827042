import { AgriculturePropertyType } from "../../../../../common/vars/valuationsAndMembershipTypes/agriculturePropertyTypes";

type TranslationNSObject = {
    [key in AgriculturePropertyType]: string;
};

type Translations = {
    de: TranslationNSObject;
    en: TranslationNSObject;
};

export const agriculturePropertyTypeTranslations: Translations = {
    de: {
        Country: "Land",
        Equestrian: "Pferdesport",
        Farm: "Bauernhaus",
        Fishfarm: "Fischfarm",
        Forestry: "Forstwirtschaft",
        Greenhouse: "Gewächshaus",
        "Horticulture/Garden Centre": "Gartenbau/Gartencenter",
        "Other Agriculture": "Sonstige Landwirtschaft",
    },
    en: {
        Country: "Country",
        Equestrian: "Equestrian",
        Farm: "Farm",
        Fishfarm: "Fishfarm",
        Forestry: "Forestry",
        Greenhouse: "Greenhouse",
        "Horticulture/Garden Centre": "Horticulture/Garden Centre",
        "Other Agriculture": "Other Agriculture",
    },
};
