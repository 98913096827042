export type StandardPropertyType = (typeof standardPropertyTypes)[number];

const standardPropertyTypes = [
    "Residential",
    "Commercial",
    "Land",
    "Agricultural",
    "Development",
    "Special",
] as const;

export default standardPropertyTypes;
