import { StandardValuationType } from "../../../../../common/vars/valuationsAndMembershipTypes/standardValuationTypes";

type TranslationNSObject = {
    [key in StandardValuationType]: string;
};

type Translations = {
    de: TranslationNSObject;
    en: TranslationNSObject;
};

export const valuationTypeTranslations: Translations = {
    de: {
        "Full Valuation": "Vollgutachten",
        "Other Valuation": "Sondergutachten",
        "Short Valuation": "Kurzgutachten",
    },
    en: {
        "Full Valuation": "Full Valuation",
        "Other Valuation": "Other Valuation",
        "Short Valuation": "Short Valuation",
    },
};
