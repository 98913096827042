export type AgriculturePropertyType = (typeof agriculturePropertyTypes)[number];

const agriculturePropertyTypes = [
    "Farm",
    "Fishfarm",
    "Forestry",
    "Horticulture/Garden Centre",
    "Greenhouse",
    "Country",
    "Equestrian",
    "Other Agriculture",
] as const;

export default agriculturePropertyTypes;
