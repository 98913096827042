import { Flex, Spacer, Switch, VStack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useField } from "formik";
import { WvwText } from "../components/typography";
import FormikConditionalSection from "./FormikConditionalSection";

type PropTypes = {
    name: string;
    options: {
        value: string;
        label: string;
        description?: string;
        conditionalSection: React.ReactElement;
    }[];
};

const FormikConditionalFieldSwitch = (props: PropTypes) => {
    const { t } = useTranslation("formik");

    const { name, options } = props;

    const [field, meta] = useField({
        ...props,
        onChange: undefined,
    });

    const values: string[] = field.value;

    const setOptionSelected = (option: string, setTo: boolean) => {
        let newValues = [...values];

        if (setTo === true) {
            newValues.push(option);
        } else {
            newValues = newValues.filter((i) => i !== option);
        }

        field.onChange({
            target: {
                name,
                value: newValues,
            },
        });
    };

    return (
        <>
            {options.map((option) => (
                <VStack
                    key={option.value}
                    width="100%"
                    alignItems="flex-start"
                    bgColor="wvwGrey05"
                    borderRadius="8"
                >
                    <Flex
                        _hover={{ cursor: "pointer" }}
                        id="switch-box"
                        width="100%"
                        padding="1rem"
                        alignItems="center"
                        onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();

                            const currentValue = values.includes(option.value);

                            setOptionSelected(option.value, !currentValue);
                        }}
                    >
                        <VStack
                            align="left"
                            spacing="0"
                        >
                            <WvwText bold>{option.label}</WvwText>

                            <WvwText>{option.description}</WvwText>
                        </VStack>

                        <Spacer />

                        <Switch
                            isChecked={values.includes(option.value)}
                            value={option.value}
                        />

                        {meta.touched && meta.error && (
                            <WvwText color="red">
                                {t(meta.error, { defaultValue: meta.error })}
                            </WvwText>
                        )}
                    </Flex>

                    <FormikConditionalSection
                        formDataTarget={name}
                        condition={(v) => v?.includes(option.value)}
                    >
                        {option.conditionalSection}
                    </FormikConditionalSection>
                </VStack>
            ))}
        </>
    );
};

export default FormikConditionalFieldSwitch;
