export const translations = {
    de: {
        institutionalClient: "Institutioneller Kunde",
        obscuredClient: "Verborgener Kunde",
        privateClient: "Privatkunde",
    },
    en: {
        institutionalClient: "Institutional Client",
        obscuredClient: "Obscured Client",
        privateClient: "Private End Customer",
    },
};
