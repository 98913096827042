import {
    GridItem,
    Heading,
    ListItem,
    SimpleGrid,
    Text,
    UnorderedList,
    VStack,
} from "@chakra-ui/react";
import { getDaysUntilDate } from "../../../../../common/functions";
import { EnquiryType } from "../../../../../types";
import { useSmartTranslation } from "../../../common/hooks/useSmartTranslation";
import { EnquiryMatchInfo } from "./EnquiryMatchInfo";
import { ValuerQualifications } from "./ValuerRequirements";
import { displayDateWithCurrentLocale } from "../../../../../common/functions/displayDateInLocale";

type Props = {
    enquiry: EnquiryType;
    matchInfo?: EnquiryMatchInfo;
};

export const EnquiryRequirements = (props: Props) => {
    const {
        enquiry: {
            valuationType,
            valuationStandards,
            completionDate,
            valuationSpecial,
            valuationPurposes,
            additionalRequirements = "-",
        },
        enquiry,
        matchInfo,
    } = props;

    const t = useSmartTranslation();

    const typeAndStandard = (
        <Subsection
            title={t("domain.enquiry.valuationRequirements.typeAndStandard")}
        >
            <Heading size="md">
                {t(`domain.standards.valuationType.${valuationType}`)}
            </Heading>
            <Text fontWeight={"bold"}>
                {t(`domain.standards.systemStandard.${valuationStandards}`)}
            </Text>
        </Subsection>
    );
    const expectedDelivery = (
        <Subsection
            title={t("domain.enquiry.valuationRequirements.expectedDelivery")}
        >
            <Heading size="md">
                {displayDateWithCurrentLocale(completionDate)}
            </Heading>
            <DaysLeft completionDate={completionDate} />
        </Subsection>
    );

    const specificScopes = (
        <Subsection
            title={t("domain.enquiry.valuationRequirements.specificScopes")}
        >
            <UnorderedList>
                {valuationSpecial &&
                    valuationSpecial.map((spec) => (
                        <ListItem
                            key={spec}
                            fontWeight="bold"
                        >
                            {t(`domain.standards.specialValuationType.${spec}`)}
                        </ListItem>
                    ))}
            </UnorderedList>
        </Subsection>
    );

    const purposes = (
        <Subsection title={t("domain.enquiry.valuationRequirements.purpose")}>
            <UnorderedList>
                {valuationPurposes.map((purp) => (
                    <ListItem
                        key={purp}
                        fontWeight="bold"
                    >
                        {t(`domain.standards.valuationOccasion.${purp}`)}
                    </ListItem>
                ))}
            </UnorderedList>
        </Subsection>
    );

    const qualifications = (
        <Subsection>
            <ValuerQualifications
                enquiry={enquiry}
                matchInfo={matchInfo}
            />
        </Subsection>
    );

    const additional = (
        <Subsection
            title={t(
                "domain.enquiry.valuationRequirements.additionalRequirements"
            )}
        >
            <Text>{additionalRequirements}</Text>
        </Subsection>
    );

    // FIXME container - grid?

    return (
        <SimpleGrid
            columns={4}
            gap={2}
        >
            {typeAndStandard}
            {expectedDelivery}
            {specificScopes}
            {purposes}
            <GridItem colSpan={4}>{qualifications}</GridItem>
            <GridItem colSpan={4}>{additional}</GridItem>
        </SimpleGrid>
    );
};

// helpers

type DaysLeftProps = {
    completionDate: string;
};

const DaysLeft = (props: DaysLeftProps) => {
    const { completionDate } = props;
    const t = useSmartTranslation();
    const daysLeft = getDaysUntilDate(new Date(completionDate));
    let color;

    if (daysLeft >= 20) color = "green.400";
    else if (daysLeft >= 10) color = "orange.400";
    else color = "red.500";
    return (
        <Text
            fontWeight="bold"
            color={color}
        >
            {t("domain.enquiry.daysLeft", {
                count: daysLeft,
            })}
        </Text>
    );
};

type SubsectionProps = {
    children: React.ReactNode | React.ReactNode[];
    title?: string;
};

const Subsection = (props: SubsectionProps) => {
    const { children, title } = props;

    // FIXME vstack config - cf. Section?
    return (
        <VStack
            bg="gray.50"
            borderRadius="8"
            align="left"
            padding="1em"
        >
            {title && (
                <Text
                    variant="secondary"
                    fontSize="12px"
                >
                    {title}
                </Text>
            )}
            <VStack
                spacing="0"
                align="left"
            >
                {children}
            </VStack>
        </VStack>
    );
};
