import parseDelimitedNumber from "./parseDelimitedNumber";

const delimitNumber = (numString = 0 as string | number) => {
    if (numString === null) return "";

    const num = Number(parseDelimitedNumber(numString.toString()));

    return num.toLocaleString(navigator.language);
};

export default delimitNumber;
