import { AgriculturePropertyType } from "./agriculture-property-type/AgriculturePropertyType";
import { CommercialPropertyType } from "./commercial-property-type.ts/CommercialPropertyType";
import { DevelopmentPropertyType } from "./development-property-type/DevelopmentPropertyType";
import { LandPropertyType } from "./land-property-type/LandPropertyType";
import { MaxOfficeDistance } from "./max-office-distance/MaxOfficeDistance";
import { MaxOperatingDistance } from "./max-operating-distance/MaxOperatingDistance";
import { MinPartners } from "./min-partners/MinPartners";
import { PropertyCondition } from "./property-condition/PropertyCondition";
import { PropertyFeature } from "./property-feature/PropertyFeature";
import { PropertyType } from "./property-type/PropertyType";
import { ResidentialPropertyType } from "./residential-property-type/ResidentialPropertyType";
import { SpecialPropertyType } from "./special-property-type/SpecialPropertyType";
import { SpecialValuationType } from "./special-valuation-type/SpecialValuationType";
import { SystemStandard } from "./system-standard/SystemStandard";
import { ValuationOccasion } from "./valuation-occasion/ValuationOccasion";
import { ValuationType } from "./valuation-type/ValuationType";
import { YearsOfExperience } from "./years-of-experience/YearsOfExperience";

export const Standards = {
    translations: {
        de: {
            agriculturePropertyType: AgriculturePropertyType.translations.de,
            commercialPropertyType: CommercialPropertyType.translations.de,
            developmentPropertyType: DevelopmentPropertyType.translations.de,
            landPropertyType: LandPropertyType.translations.de,
            maxOfficeDistance: MaxOfficeDistance.translations.de,
            maxOperatingDistance: MaxOperatingDistance.translations.de,
            minPartners: MinPartners.translations.de,
            propertyCondition: PropertyCondition.translations.de,
            propertyFeature: PropertyFeature.translations.de,
            propertyType: PropertyType.translations.de,
            residentialPropertyType: ResidentialPropertyType.translations.de,
            specialPropertyType: SpecialPropertyType.translations.de,
            specialValuationType: SpecialValuationType.translations.de,
            systemStandard: SystemStandard.translations.de,
            valuationOccasion: ValuationOccasion.translations.de,
            valuationType: ValuationType.translations.de,
            yearsOfExperience: YearsOfExperience.translations.de,
        },
        en: {
            agriculturePropertyType: AgriculturePropertyType.translations.en,
            commercialPropertyType: CommercialPropertyType.translations.en,
            developmentPropertyType: DevelopmentPropertyType.translations.en,
            landPropertyType: LandPropertyType.translations.en,
            maxOfficeDistance: MaxOfficeDistance.translations.en,
            maxOperatingDistance: MaxOperatingDistance.translations.en,
            minPartners: MinPartners.translations.en,
            propertyCondition: PropertyCondition.translations.en,
            propertyFeature: PropertyFeature.translations.en,
            propertyType: PropertyType.translations.en,
            residentialPropertyType: ResidentialPropertyType.translations.en,
            specialPropertyType: SpecialPropertyType.translations.en,
            specialValuationType: SpecialValuationType.translations.en,
            systemStandard: SystemStandard.translations.en,
            valuationOccasion: ValuationOccasion.translations.en,
            valuationType: ValuationType.translations.en,
            yearsOfExperience: YearsOfExperience.translations.en,
        },
    },
};
