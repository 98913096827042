import { StandardValuerMaxOperatingDistance } from "../../../../../common/vars/valuationsAndMembershipTypes/standardValuerMaxOperatingDistanceTypes";

type TranslationNSObject = {
    [key in StandardValuerMaxOperatingDistance]: string;
};

type Translations = {
    de: TranslationNSObject;
    en: TranslationNSObject;
};

export const maxOperatingDistanceTranslations: Translations = {
    de: {
        "5": "Bis zu 5 Kilometer",
        "10": "Bis zu 10 Kilometer",
        "20": "Bis zu 20 Kilometer",
        "50": "Bis zu 50 Kilometer",
        "100": "Bis zu 100 Kilometer",
    },
    en: {
        "5": "Up to 5 kilometers",
        "10": "Up to 10 kilometers",
        "20": "Up to 20 kilometers",
        "50": "Up to 50 kilometers",
        "100": "Up to 100 kilometers",
    },
};
