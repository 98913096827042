import { Button } from "@chakra-ui/react";
import { EnquiryType } from "../../../../types";
import { useSmartTranslation } from "../../common/hooks/useSmartTranslation";
import useEnquiryCancel from "./useEnquiryCancelV2";

type ParamsType = {
    onSuccess?: (data?: any) => void;
    onError?: (error: Error) => void;
    enquiry: EnquiryType;
};

const CancelEnquiry = (params: ParamsType) => {
    const {
        enquiry: { status },
        enquiry,
        onSuccess,
        onError,
    } = params;

    const t = useSmartTranslation();

    const { cancelEnquiry, confirmationModal } = useEnquiryCancel({
        onSuccess,
        onError,
        enquiry,
    });

    const canCancel = status === "PENDING";

    return (
        <Button
            isDisabled={!canCancel}
            onClick={() => cancelEnquiry()}
        >
            {t("domain.enquiry.ACTIVITIES.cancel.NAME")}
            {confirmationModal}
        </Button>
    );
};

export default CancelEnquiry;
