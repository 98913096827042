import { BrowserRouter } from "react-router-dom";
import SiteLoading from "../../../common/components/SiteLoading";
import { useSmartTranslation } from "../common/hooks/useSmartTranslation";
import { getRoutes } from "./routes/routes";
import useMe from "./useMeV2";

const AppV2 = () => {
    const { pathname } = window.location;

    const t = useSmartTranslation();

    const { data: user, isLoading, fbError, error: backendError } = useMe();

    const error = fbError || backendError;

    if (error) {
        return <div>error</div>;
    }

    if (isLoading) {
        if (pathname.includes("/direct-enquiry-form/")) {
            return (
                <SiteLoading
                    noLogo
                    text={t("common.loading")}
                />
            );
        }

        return <SiteLoading />;
    }

    const routes = getRoutes(user);

    return <BrowserRouter>{routes}</BrowserRouter>;
};

export default AppV2;
