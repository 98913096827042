import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { Flex, Heading, Spacer, Text, VStack } from "@chakra-ui/layout";
import { Collapse, IconButton, useDisclosure } from "@chakra-ui/react";
import { ReactNode } from "react";

type SectionProps = {
    title?: string;
    subtitle?: string;
    content: ReactNode;
    collapsable?: boolean;
};

const Section = (props: SectionProps) => {
    const { title, subtitle, content: contentRaw, collapsable = false } = props;

    const { isOpen, onToggle } = useDisclosure({
        defaultIsOpen: true,
    });

    let toggleExpansionState;
    let content;

    if (collapsable) {
        content = <Collapse in={isOpen}>{contentRaw}</Collapse>;

        let ToggleIcon;
        let ariaLabel;

        if (isOpen) {
            ToggleIcon = ChevronUpIcon;
            ariaLabel = "Collapse section";
        } else {
            ToggleIcon = ChevronDownIcon;
            ariaLabel = "Expand section";
        }

        toggleExpansionState = (
            <IconButton
                size={"24px"}
                background={"transparent"}
                aria-label={ariaLabel}
                onClick={onToggle}
                icon={
                    <ToggleIcon
                        boxSize={"24px"}
                        color={"gray.400"}
                    />
                }
            />
        );
    } else content = contentRaw;

    return (
        <VStack
            width="100%"
            align="left"
            padding="1em"
            bg="white"
            borderRadius="8"
        >
            <Flex
                alignItems="center"
                mb={"1em"}
            >
                {title && (
                    <Heading
                        size="md"
                        variant="blue"
                    >
                        {title}
                    </Heading>
                )}
                {subtitle && <Text variant="grey">{subtitle}</Text>}
                <Spacer />
                {toggleExpansionState}
            </Flex>
            {content}
        </VStack>
    );
};

export { Section };

