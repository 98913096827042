import { translations } from "./i18n";
import { NoOffersView } from "./NoOffersView";
import { offerColumns } from "./OfferColumns";
import { calculateDeliveryDate } from "./offerFunctions";

export const Offer = {
    calculateDeliveryDate,
    NoOffersView: NoOffersView,
    TableColumns: offerColumns,
    translations: translations,
};
