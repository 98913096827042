import { HStack, SimpleGrid, Text, VStack } from "@chakra-ui/layout";
import { Button, Select } from "@chakra-ui/react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useRole, useToast } from "../../../../../../common/hooks";
import { OfferType } from "../../../../../../types";
import {
    useEnquiry,
    useEnquiryGetDormant,
    useOfferGetByEnquiryId
} from "../../../../../../utils/api/hooks";
import LoadingSpinner from "../../../../common/components/LoadingSpinner";
import {
    TranslationFunction,
    useSmartTranslation
} from "../../../../common/hooks/useSmartTranslation";
import { Enquiry, Offer, Property } from "../../../../domain";
import { OfferHistoryTable } from "../../../../domain/offer/OfferHistoryTable";
import useRejectMultipleOffers from "../../../../domain/offer/useRejectMultipleOffers";
import { Section } from "../../../../layout";
import { EnrichedOfferTable } from "./EnrichedOfferTable";

const EnquiryDetails = () => {
    const t = useSmartTranslation();

    const { createErrorToast } = useToast();

    const { enquiryId } = useParams();

    const { roleLoading, userIsClient } = useRole();

    const {
        enquiry,
        refresh: refreshEnquiry,
        isLoading: enquiryLoading,
    } = useEnquiry({ enquiryId });

    const {
        data: dormantEnquiries,
        isLoading: loadingDormancy,
        refresh: refreshDormancy,
    } = useEnquiryGetDormant();

    // offers

    const {
        data: offers,
        isLoading: offersLoading,
        refresh: refreshOffers,
    } = useOfferGetByEnquiryId(Number(enquiryId));

    const [checkedOffers, setCheckedOffers] = useState<OfferType[]>([]);

    const onError = (error: Error) => createErrorToast(error.message);

    const onUpdateEnquiry = () => {
        refreshEnquiry();
        refreshDormancy();
    };

    const { rejectOffers, confirmationModal: offerRejectionConfirmationModal } =
        useRejectMultipleOffers({
            onError,
            refresh: refreshOffers,
            offers: checkedOffers,
        });

    const offerActivities = createOfferActivities(rejectOffers);

    const [activeOfferActivity, setActiveOfferActivity] =
        useState<OfferActivityType>(offerActivities["REJECT"]);

    if (
        roleLoading ||
        !enquiry ||
        enquiryLoading ||
        !dormantEnquiries ||
        loadingDormancy ||
        !offers ||
        offersLoading
    )
        return <LoadingSpinner />;

    // enquiry

    const dormantForUser = dormantEnquiries
        .map((enq) => enq.id)
        .includes(enquiry.id);

    const title = (
        <Enquiry.Title
            enquiry={enquiry}
            dormantForUser={dormantForUser}
            userIsClient={userIsClient}
            onUpdate={onUpdateEnquiry}
            onError={onError}
        />
    );

    const requestedValuationSection = (
        <Section
            collapsable
            title={t("domain.enquiry.requestedValuationTitle")}
            content={<Enquiry.Requirements enquiry={enquiry} />}
        />
    );

    // offers

    const activeOffers = offers.filter((o) => o.status === "PENDING");

    const toggleOfferChecked = (offer: OfferType, check: boolean) => {
        if (!check) {
            const idx = checkedOffers.indexOf(offer);
            if (idx !== -1) {
                const newChecked = [...checkedOffers];
                newChecked.splice(idx, 1);
                setCheckedOffers(newChecked);
            }
        } else {
            if (checkedOffers.includes(offer)) return;
            setCheckedOffers([...checkedOffers, offer]);
        }
    };

    const offersFooter = checkedOffers.length && (
        <HStack>
            <Text>
                {t("clientEnquiryDetails.activeOffers.footer.selected", {
                    count: checkedOffers.length,
                })}
            </Text>
            <Select
                width={"140px"}
                value={activeOfferActivity.key}
                onChange={(e) =>
                    setActiveOfferActivity(
                        createOfferActivities(rejectOffers)[e.target.value]
                    )
                }
            >
                {createOptions(offerActivities, t)}
            </Select>
            <Button onClick={() => activeOfferActivity.execute(checkedOffers)}>
                {t("clientEnquiryDetails.activeOffers.footer.apply")}
            </Button>
            {offerRejectionConfirmationModal}
        </HStack>
    );

    const offersSectionContent = offers.length ? (
        <EnrichedOfferTable
            offers={activeOffers}
            checkedOffers={checkedOffers}
            toggleOfferChecked={toggleOfferChecked}
            footerContent={offersFooter}
        />
    ) : (
        <Offer.NoOffersView />
    );

    const offersSection = (
        <Section
            title={`${t("clientEnquiryDetails.activeOffers.title", {
                count: activeOffers.length,
            })}`}
            content={offersSectionContent}
        />
    );

    // offer history

    const historicOffers = offers.filter((o) =>
        ["REJECTED", "WITHDRAWN"].includes(o.status)
    );

    const historyContent = <OfferHistoryTable offers={historicOffers} />;

    const offerHistorySection = (
        <Section
            title={t("clientEnquiryDetails.offerHistory.title")}
            content={historyContent}
        />
    );

    // properties

    const properties = enquiry.properties;

    const propertyPortfolioSection = (
        <Section
            title={t("clientEnquiryDetails.propertyPortfolio.title", {
                count: properties.length,
            })}
            content={<Property.PortfolioTable properties={properties} />}
        />
    );

    // render

    return (
        <VStack
            w={"100%"}
            padding=".5em"
            spacing={4}
        >
            {title}
            <SimpleGrid
                columns={2}
                spacing={2}
            >
                {requestedValuationSection}
                {offersSection}
                {propertyPortfolioSection}
                {offerHistorySection}
            </SimpleGrid>
        </VStack>
    );
};

type OfferActivityType = {
    key: string;
    label: (t: TranslationFunction) => string;
    execute: ((offers: OfferType[]) => void) | (() => void);
};

const createOfferActivities = (
    rejectOffers: () => void
): Record<string, OfferActivityType> => {
    return {
        REJECT: {
            key: "REJECT",
            label: (t) => t("domain.offer.ACTIVITIES.reject.NAME"),
            execute: () => rejectOffers(),
        },
    };
};

const createOptions = (
    options: Record<string, OfferActivityType>,
    t: TranslationFunction
) => {
    return Object.entries(options).map(([key, option]) => (
        <option
            key={key}
            value={key}
        >
            {option.label(t)}
        </option>
    ));
};

export default EnquiryDetails;
