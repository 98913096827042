import { Button, Heading } from "@chakra-ui/react";
import { useContext } from "react";
import delimitNumber from "../../../../../../../common/functions/delimitNumber";
import {
    EnquiryWithFromUserAndFromEntityType,
    OfferOnlyType,
} from "../../../../../../../types";
import { UserContext } from "../../../../../app/useMeV2";
import { enquiryColumns } from "../../../../../common/components/data-table/columns/EnquiryColumns";
import { DataTableColumnType } from "../../../../../common/components/data-table/DataTable";
import { User } from "../../../../../domain";

type TableDataType = EnquiryWithFromUserAndFromEntityType & {
    offers: OfferOnlyType[];
};

// create table data
type RecentMatchesProps = {
    enquiries: EnquiryWithFromUserAndFromEntityType[];
    offers: OfferOnlyType[];
    user: User;
};

/*
"show only enquiries we can submit an offer on"
ie.
- enquiry is pending
- the user hasn't submitted an offer on the enquiry
*/
export const createRecentMatchesData = (props: RecentMatchesProps) => {
    const { enquiries, offers, user } = props;

    const recentMatchData = enquiries
        .filter((enquiry) => {
            if (enquiry.status !== "PENDING") return false;

            if (
                offers.find(
                    (offer) =>
                        offer.enquiryId === enquiry.id &&
                        offer.fromValuerId === user.id
                )
            )
                return false;

            return true;
        })
        .map((enquiry): TableDataType => {
            const enquiryOffers = offers.filter(
                (offer) => offer.enquiryId === enquiry.id
            );

            return {
                ...enquiry,
                offers: enquiryOffers,
            };
        });

    return recentMatchData;
};

export const myOffer: DataTableColumnType<TableDataType> = {
    alignment: "left",
    title: (t) =>
        t(`common.components.dataTable.columns.enquiryColumns.myOffer`),
    render: (i, t) => {
        const user = useContext(UserContext);

        const myOffer = i.offers.find(
            (offer) => offer.fromValuerId === user?.id
        );

        const myOfferPrice = `€${delimitNumber(myOffer?.price)}`;

        if (!myOffer) {
            return <Button>{t("domain.offer.submit")}</Button>;
        } else {
            return (
                <Heading
                    size="sm"
                    color="green.500"
                >
                    {myOfferPrice}
                </Heading>
            );
        }
    },
};

// columns
export const columns: DataTableColumnType<TableDataType>[] = [
    enquiryColumns.enquiryIdWithProperties,
    enquiryColumns.valuationRequirements,
    enquiryColumns.piCoverRequirements,
    enquiryColumns.offers,
    enquiryColumns.expectedDeliveryDate,
    enquiryColumns.estPortfolioValueEnquiry,
    enquiryColumns.viewClientEnquiry,
];
