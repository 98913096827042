import { CheckIcon, WarningTwoIcon } from "@chakra-ui/icons";
import { Flex, HStack, Text, VStack } from "@chakra-ui/react";
import standardMinimumPartners from "../../../../../common/vars/valuationsAndMembershipTypes/standardMinimumPartners";
import standardRequiredYearsOfExperience from "../../../../../common/vars/valuationsAndMembershipTypes/standardRequiredYearsOfExperienceRequired";
import { EnquiryType } from "../../../../../types";
import { useSmartTranslation } from "../../../common/hooks/useSmartTranslation";
import { MISSING_TRANSLATION } from "../../../common/MISSING_TRANSLATION";
import { NO_DATA } from "../../../common/NO_DATA";
import { Enquiry } from "../Enquiry";
import { calculateRequiredAbsolutePICover } from "../enquiryFunctions";
import { EnquiryMatchInfo, PICoverMatchInfo } from "./EnquiryMatchInfo";

type ValuerQualifications = {
    enquiry: EnquiryType;
    matchInfo?: EnquiryMatchInfo;
};

export const ValuerQualifications = (props: ValuerQualifications) => {
    const {
        enquiry: {
            valuerExperience,
            valuerMaxOfficeDistance,
            valuerMinimumPICoverPercentage,
            valuerMinimumPartners,
        },
        enquiry,
        matchInfo,
    } = props;

    const requiredPICoverAbsolute = calculateRequiredAbsolutePICover(enquiry);

    const valuermatchesStandards = matchInfo?.valuationStandards;
    const valuerMatchesExperience = matchInfo?.valuerExperience;
    const valuerMatchesMinPartners = matchInfo?.valuerMinimumPartners;
    const valuerMatchesMaxDistance = matchInfo?.valuerMaxOfficeDistance;
    const valuerMatchesPICover = matchInfo?.valuerPICover;

    return (
        <VStack
            align="left"
            spacing="0"
        >
            <ValuerQualification
                enquiry={enquiry}
                matches={valuermatchesStandards}
            />
            <ValuerExperience
                required={valuerExperience}
                matches={valuerMatchesExperience}
            />
            <ValuerMinPartners
                minPartners={valuerMinimumPartners}
                matches={valuerMatchesMinPartners}
            />
            <ValuerMaxDistance
                maxDistance={valuerMaxOfficeDistance}
                matches={valuerMatchesMaxDistance}
            />
            <ValuerPICover
                requiredPICoverAbsolute={requiredPICoverAbsolute}
                requiredPICoverPercentage={valuerMinimumPICoverPercentage}
                matchInfo={valuerMatchesPICover}
            />
        </VStack>
    );
};

// qualification

type ValuerQualificationProps = {
    enquiry: EnquiryType;
    matches?: boolean;
};

const ValuerQualification = (props: ValuerQualificationProps) => {
    const { enquiry, matches } = props;

    const t = useSmartTranslation();

    const standard = Enquiry.determineStandard(enquiry, t);

    return (
        <ValuerRequirement
            label={"domain.enquiry.valuerRequirements.qualification"}
            value={standard}
            match={calcMatch(matches)}
        />
    );
};

// min partners

type ValuerMinPartnersProps = {
    minPartners: string;
    matches?: boolean;
};

const ValuerMinPartners = (props: ValuerMinPartnersProps) => {
    const { minPartners, matches } = props;

    const t = useSmartTranslation();

    let partners;
    if (minPartners) {
        const requiredKey = standardMinimumPartners.find(
            (s) => s.value === minPartners
        )?.value;
        partners = t("domain.standards.minPartners." + requiredKey, {
            defaultValue: NO_DATA,
        });
    }

    return (
        <ValuerRequirement
            label={"domain.enquiry.valuerRequirements.minPartners"}
            value={partners}
            match={calcMatch(matches)}
        />
    );
};

// pi cover

type ValuerPICoverProps = {
    requiredPICoverAbsolute?: number;
    requiredPICoverPercentage?: number;
    matchInfo?: PICoverMatchInfo;
};

const ValuerPICover = (props: ValuerPICoverProps) => {
    const { requiredPICoverAbsolute, requiredPICoverPercentage, matchInfo } =
        props;

    const matches = matchInfo?.matches;

    // render both
    const piCover = `€${requiredPICoverAbsolute}/ ${requiredPICoverPercentage}\%`;

    return (
        <ValuerRequirement
            label={"domain.enquiry.valuerRequirements.minPiCover"}
            value={piCover}
            match={calcMatch(matches)}
        />
    );
};

// experience

type ValuerExperienceProps = {
    required: string;
    matches?: boolean;
};

const ValuerExperience = (props: ValuerExperienceProps) => {
    const { required, matches } = props;

    const t = useSmartTranslation();

    let experience;
    if (required) {
        const requiredKey = standardRequiredYearsOfExperience.find(
            (s) => s.value === required
        )?.value;
        experience = t("domain.standards.yearsOfExperience." + requiredKey, {
            defaultValue: NO_DATA,
        });
    }

    return (
        <ValuerRequirement
            label={"domain.enquiry.valuerRequirements.minExperience"}
            value={experience}
            match={calcMatch(matches)}
        />
    );
};

// max distance

type ValuerMaxDistanceProps = {
    maxDistance: string;
    matches?: boolean;
};

const ValuerMaxDistance = (props: ValuerMaxDistanceProps) => {
    const t = useSmartTranslation();

    const { maxDistance, matches } = props;

    const maxOperatingDistance = t(
        "domain.standards.maxOfficeDistance." + maxDistance,
        { defaultValue: NO_DATA }
    );

    return (
        <ValuerRequirement
            label={"domain.enquiry.valuerRequirements.maxDistance"}
            value={maxOperatingDistance}
            match={calcMatch(matches)}
        />
    );
};

// base requirements

const calcMatch = (matches: boolean | undefined) => {
    if (matches === undefined) return undefined;
    return matches ? "YES" : "NO";
};

const matchResults = {
    YES: <CheckIcon color="green.400" />,
    PARTIAL: <div>PARTIAL</div>,
    NO: <WarningTwoIcon color="orange.400" />,
};

type MatchResultType = keyof typeof matchResults | undefined;

type ValuerRequirementProps = {
    label: string;
    value?: string | string[] | number;
    match: MatchResultType;
};

const ValuerRequirement = (props: ValuerRequirementProps) => {
    const { label, value, match } = props;

    const t = useSmartTranslation();

    const translatedLabel = t(label, { defaultValue: MISSING_TRANSLATION });

    const labelText = (
        <Text
            w="12em"
            fontSize="14px"
        >
            {translatedLabel}
        </Text>
    );

    const matchIcon = match && matchResults[match];

    const valueText = value !== undefined ? (
        <Text
            fontSize="14px"
            fontWeight="bold"
        >
            {value}
        </Text>
    ) : (
        <Text
            fontSize="14px"
            variant="secondary"
        >
            {t("domain.enquiry.valuerRequirements.noRequirement")}
        </Text>
    );

    return (
        <Flex
            gap={6}
            w="100%"
            minW="max"
        >
            {labelText}
            <HStack spacing="2">
                {matchIcon}
                {valueText}
            </HStack>
        </Flex>
    );
};
