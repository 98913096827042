export const descending = (a: Number, b: Number) => {
    if (a > b) return -1;
    if (a < b) return 1;
    return 0;
};

export const ascending = (a: Number, b: Number) => {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
};
