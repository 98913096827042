import {
    Flex,
    GridItem,
    HStack,
    SimpleGrid,
    Text,
    VStack,
} from "@chakra-ui/react";
import { PropertyType } from "../../../../../../types";
import { useSmartTranslation } from "../../../../common/hooks/useSmartTranslation";
import PropertyDocumentList from "./PropertyDocumentList";
import { NO_DATA } from "../../../../common/NO_DATA";
import delimitNumber from "../../../../../../common/functions/delimitNumber";

type Props = {
    property: PropertyType;
};

export const ValuerPropertyDetails = (props: Props) => {
    const { property } = props;

    const t = useSmartTranslation();

    const estValue = (
        <Subsection
            title={t(
                "screens.platform.valuer.valuerEnquiryDetails.propertyDetails.estimateValue"
            )}
        >
            <Text
                fontWeight="bold"
                color="cyan.500"
                fontSize={14}
            >
                {`€${delimitNumber(property.estimateValue)}`}
            </Text>
        </Subsection>
    );

    const area = (
        <Subsection
            title={t(
                "screens.platform.valuer.valuerEnquiryDetails.propertyDetails.area"
            )}
        >
            <HStack
                spacing={0}
                fontWeight="bold"
                color="gray.900"
                fontSize={14}
            >
                <Text>{`${delimitNumber(property.areaGrossInternal)}`}/</Text>
                <Text>{`${
                    property.areaOverall !== undefined
                        ? delimitNumber(property.areaOverall)
                        : NO_DATA
                } m²`}</Text>
            </HStack>
        </Subsection>
    );

    const conditionProperty = property.propertyCondition
        ? t(`domain.standards.propertyCondition.${property.propertyCondition}`)
        : NO_DATA;

    const condition = (
        <Subsection
            title={t(
                "screens.platform.valuer.valuerEnquiryDetails.propertyDetails.condition"
            )}
        >
            <Text
                fontWeight="bold"
                color="gray.900"
                fontSize={14}
            >
                {conditionProperty}
            </Text>
        </Subsection>
    );

    const buildYear = (
        <Subsection
            title={t(
                "screens.platform.valuer.valuerEnquiryDetails.propertyDetails.buildYear"
            )}
        >
            <Text
                fontWeight="bold"
                color="gray.900"
                fontSize={14}
            >
                {`${property.buildYear ? property.buildYear : NO_DATA}`}
            </Text>
        </Subsection>
    );

    const propertyType = (
        <Subsection>
            <HStack
                spacing={2}
                align="start"
            >
                <Text
                    variant="secondary"
                    fontSize={14}
                >
                    {t(
                        "screens.platform.valuer.valuerEnquiryDetails.propertyDetails.propertyType"
                    )}
                </Text>
                <VStack align="start">
                    {property.propertyTypes.map((type, i) => (
                        <Text
                            key={i}
                            fontWeight="bold"
                            color="gray.900"
                            fontSize={14}
                        >
                            {t(`domain.standards.propertyType.${type}`)}
                        </Text>
                    ))}
                </VStack>
            </HStack>
        </Subsection>
    );

    const propertyParking = property.propertyFeatures
        ? property.propertyFeatures
              .map((parking) =>
                  t(`domain.standards.propertyFeature.${parking}`)
              )
              .join(", ")
        : NO_DATA;

    const parking = (
        <Subsection>
            <HStack
                spacing={2}
                align="start"
            >
                <Text
                    variant="secondary"
                    fontSize={14}
                >
                    {t(
                        "screens.platform.valuer.valuerEnquiryDetails.propertyDetails.parking"
                    )}
                </Text>
                <Flex
                    align="start"
                    wrap="wrap"
                    gap={1}
                >
                    <Text
                        fontWeight="bold"
                        color="gray.900"
                        fontSize={14}
                    >
                        {propertyParking}
                    </Text>
                </Flex>
            </HStack>
        </Subsection>
    );

    const specialFeatures = (
        <Subsection>
            <HStack
                spacing={2}
                align="start"
            >
                <Text
                    variant="secondary"
                    fontSize={14}
                >
                    {t(
                        "screens.platform.valuer.valuerEnquiryDetails.propertyDetails.specialFeatures"
                    )}
                </Text>
                <Flex
                    align="start"
                    wrap="wrap"
                    gap={1}
                >
                    <Text
                        fontWeight="bold"
                        color="gray.900"
                        fontSize={14}
                    >
                        {property.otherFeatures.join(", ")}
                    </Text>
                </Flex>
            </HStack>
        </Subsection>
    );

    const documents = (
        <>
            <Text
                fontSize={12}
                color="gray.500"
                mb={1}
            >
                {t(
                    "screens.platform.valuer.valuerEnquiryDetails.propertyDetails.documents",
                    { count: property.documents?.length }
                )}
            </Text>
            <VStack
                bg="gray.50"
                borderRadius="8"
                align="left"
                spacing={0}
            >
                <PropertyDocumentList documents={property.documents} />
            </VStack>
        </>
    );

    return (
        <SimpleGrid
            columns={4}
            gap={2}
        >
            {estValue}
            {area}
            {condition}
            {buildYear}
            <GridItem colSpan={4}>{propertyType}</GridItem>
            <GridItem colSpan={4}>{parking}</GridItem>
            <GridItem colSpan={4}>{specialFeatures}</GridItem>
            <GridItem colSpan={4}>{documents}</GridItem>
        </SimpleGrid>
    );
};

type SubsectionProps = {
    children: React.ReactNode | React.ReactNode[];
    title?: string;
};

const Subsection = (props: SubsectionProps) => {
    const { children, title } = props;

    return (
        <VStack
            bg="gray.50"
            borderRadius="8"
            align="left"
            padding="1rem"
        >
            {title && (
                <Text
                    variant="secondary"
                    fontSize={14}
                >
                    {title}
                </Text>
            )}
            {children}
        </VStack>
    );
};
