import { ResidentialPropertyFlatType } from "../../../../../common/vars/valuationsAndMembershipTypes/residentialPropertyFlatTypes";
import { ResidentialPropertyHouseType } from "../../../../../common/vars/valuationsAndMembershipTypes/residentialPropertyHouseTypes";
import { ResidentialPropertyOtherType } from "../../../../../common/vars/valuationsAndMembershipTypes/residentialPropertyOtherTypes";

type ResidentialPropertyTypesUnion =
    | ResidentialPropertyFlatType
    | ResidentialPropertyHouseType
    | ResidentialPropertyOtherType;

type TranslationNSObject = {
    [key in ResidentialPropertyTypesUnion]: string;
};

type Translations = {
    de: TranslationNSObject;
    en: TranslationNSObject;
};

export const residentialPropertyTypeTranslations: Translations = {
    de: {
        Apartment: "Etagenwohnung",
        Bungalow: "Bungalow",
        Detached: "Freistehend",
        "End-Terraced": "Reihenendhaus",
        Farm: "Bauernhaus",
        "First Floor": "Erdgeschoss",
        "Holiday Let": "Ferienvermietung",
        Maisonette: "Maisonette",
        "Multi-Unit": "Mehrfamilienhaus",
        Other: "Andere",
        Penthouse: "Penthouse",
        "Semi-Commercial": "Mischeinheit",
        "Semi-Detached": "Doppelhaushälfte",
        Terraced: "Reihenhaus",
        "Top Floor": "Dachgeschosswohnung",
    },
    en: {
        Apartment: "Apartment",
        Bungalow: "Bungalow",
        Detached: "Detached",
        "End-Terraced": "End-Terraced",
        Farm: "Farm",
        "First Floor": "First Floor",
        "Holiday Let": "Holiday Let",
        Maisonette: "Maisonette",
        "Multi-Unit": "Multi-Unit",
        Other: "Other",
        Penthouse: "Penthouse",
        "Semi-Commercial": "Semi-Commercial",
        "Semi-Detached": "Semi-Detached",
        Terraced: "Terraced",
        "Top Floor": "Top Floor",
    },
};
