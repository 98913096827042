import { Box, Flex, HStack, SimpleGrid, VStack } from "@chakra-ui/react";
import { useRole } from "../../../../../../common/hooks";
import {
    useCaseActiveCases,
    useEnquiryGetAll,
    useMyEntity,
    useOfferGetAllMyOffers,
} from "../../../../../../utils/api/hooks";
import useMe from "../../../../app/useMeV2";
import DataTable from "../../../../common/components/data-table/DataTable";
import AwaitingAccessBanner from "../../../../common/features/awaiting-access/AwaitingAccessBanner";
import LoadingSpinner from "../../../../common/components/LoadingSpinner";
import Statistics from "../../../../common/features/statistics/Statistics";
import { useSmartTranslation } from "../../../../common/hooks/useSmartTranslation";
import { Section } from "../../../../layout/Section";
import CustomizeExperience from "../../client/customize-experience/CustomizeExperience";
import ProfileCompletionBanner from "../../profile-completion/ProfileCompletionBanner";
import { columns as caseColumns } from "./data-table/CasesTable";
import {
    columns as enquiryColumns,
    createRecentMatchesData,
} from "./data-table/EnquiryTable";
import {
    allActiveCases,
    allMatchingEnquiriesStatistic,
    allOffersSentStatistic,
    allPendingEnquiriesStatistic,
    allRejectedOffersStatistic,
    caseConversionRate,
} from "./EnquiryAndCaseStatistics";
import { GoogleMapV2 } from "../../../../common/components/display";

const { REACT_APP_FEATURE_ENABLE_CASE = "false" } = process.env;

// helpers

const Dashboard = () => {
    const { data: enquiries = [], isLoading: enquiriesLoading } =
        useEnquiryGetAll();
    const { data: offers = [], isLoading: offersLoading } =
        useOfferGetAllMyOffers();
    const { data: entity, isLoading: entityLoading } = useMyEntity();
    const { data: cases = [], isLoading: casesLoading } = useCaseActiveCases();
    const { data: user, isLoading: userLoading } = useMe();
    const { userIsValuer, userIsClient, userIsCompanyAdmin, roleLoading } =
        useRole();

    const awaitingAccess = user?.accessPending === true;

    const enabledCases = REACT_APP_FEATURE_ENABLE_CASE === "true";

    const t = useSmartTranslation();

    const allEnquiryPropertyLocations = enquiries.flatMap((enquiry) =>
        enquiry.properties.map((property) => ({
            id: property.id,
            lat: Number(property.latitude) || 0,
            lng: Number(property.longitude) || 0,
        }))
    );

    if (userLoading || roleLoading) return <LoadingSpinner />;

    if (
        !user ||
        enquiriesLoading ||
        offersLoading ||
        entityLoading ||
        casesLoading ||
        userLoading
    )
        return <LoadingSpinner />;

    // profile completion
    const profileCompletionBanner = (
        <ProfileCompletionBanner
            user={user}
            entity={entity}
            userRoles={{
                userIsClient,
                userIsCompanyAdmin,
                userIsValuer,
            }}
        />
    );

    // statistics

    const numPendingEnquiries = allPendingEnquiriesStatistic(enquiries);
    const numMatchingEnquiries = allMatchingEnquiriesStatistic(enquiries);
    const numAllOffersSent = allOffersSentStatistic(offers, enquiries);
    const numAllRejectedOffers = allRejectedOffersStatistic(offers, enquiries);
    const numAllActiveCases = allActiveCases(cases, enquiries);
    const percentageOfCompletedCases = caseConversionRate(cases, enquiries);
    const enquiryStatsData = [
        numPendingEnquiries,
        numMatchingEnquiries,
        numAllOffersSent,
        numAllRejectedOffers,
    ];
    const caseStatsData = [numAllActiveCases, percentageOfCompletedCases];

    // statistics
    const enquiryStats = (
        <Section
            title={t("domain.enquiry.enquiries")}
            content={<Statistics stats={enquiryStatsData} />}
        />
    );

    const caseStats = (
        <Section
            title={t("domain.case.cases")}
            content={<Statistics stats={caseStatsData} />}
        />
    );

    // enquiries
    const recentMatchesData = createRecentMatchesData({
        enquiries,
        offers,
        user,
    });

    const recentMatches = (
        <Section
            title={t("valuerDashboard.recentMatchesWithCount", {
                count: recentMatchesData.length,
            })}
            content={
                <DataTable
                    data={recentMatchesData}
                    columns={enquiryColumns}
                    noDataText={t("valuerDashboard.noRecentMatches")}
                />
            }
        />
    );

    // cases
    const ongoingCases = (
        <Section
            title={t("valuerDashboard.activeCasesWithCount", {
                count: cases.length,
            })}
            content={
                <DataTable
                    data={cases}
                    columns={caseColumns}
                    noDataText={t("valuerDashboard.noAcceptedStartedValuation")}
                />
            }
        />
    );

    // account and profile
    const experienceCustomization = (
        <Section
            title={t(
                "valuerDashboard.customizeExperience.customizeYourExperience"
            )}
            content={
                <CustomizeExperience
                    customizeList={[
                        {
                            title: t(
                                "valuerDashboard.customizeExperience.completeProfile.title"
                            ),
                            subtext: t(
                                "valuerDashboard.customizeExperience.completeProfile.subtext"
                            ),
                            link: {
                                label: t("common.button.complete"),
                                nav: "profile",
                            },
                        },
                        {
                            title: t(
                                "valuerDashboard.customizeExperience.addTeamMembers.title"
                            ),
                            subtext: t(
                                "valuerDashboard.customizeExperience.addTeamMembers.subtext"
                            ),
                            link: {
                                label: t("common.button.complete"),
                                nav: "profile/team",
                            },
                        },
                        {
                            title: t(
                                "valuerDashboard.customizeExperience.uploadMarketing.title"
                            ),
                            subtext: t(
                                "valuerDashboard.customizeExperience.uploadMarketing.subtext"
                            ),
                            link: {
                                label: t("common.button.complete"),
                                nav: "/dashboard/account/documents",
                            },
                        },
                        {
                            title: t(
                                "valuerDashboard.customizeExperience.uploadPiCover.title"
                            ),
                            subtext: t(
                                "valuerDashboard.customizeExperience.uploadPiCover.subtext"
                            ),
                            link: {
                                label: t("common.button.complete"),
                                nav: "/dashboard/account",
                            },
                        },
                        {
                            title: t(
                                "valuerDashboard.customizeExperience.uploadValuationSample.title"
                            ),
                            subtext: t(
                                "valuerDashboard.customizeExperience.uploadValuationSample.subtext"
                            ),
                            link: {
                                label: t("common.button.complete"),
                                nav: "/dashboard/account/documents",
                            },
                        },
                    ]}
                />
            }
        />
    );

    const caseDependentStatistics = (
        <SimpleGrid
            gap={2}
            w="100%"
            minChildWidth="sm"
        >
            {enquiryStats}
            {enabledCases && caseStats}
        </SimpleGrid>
    );

    const caseDependentMatchingsAndCases = (
        <SimpleGrid
            gap={2}
            w="100%"
            minChildWidth={{ base: "100%", lg: "76rem" }}
        >
            {recentMatches}
            {!enabledCases && (
                <Box
                    bgColor="white"
                    padding="1rem"
                    borderRadius={8}
                    minH="12rem"
                >
                    <GoogleMapV2
                        height="100%"
                        width="100%"
                        markers={allEnquiryPropertyLocations}
                    />
                </Box>
            )}
        </SimpleGrid>
    );

    return (
        <VStack
            w="100%"
            padding=".5em"
        >
            {profileCompletionBanner}
            {awaitingAccess && (
                <AwaitingAccessBanner />
            )}
            {caseDependentStatistics}
            {caseDependentMatchingsAndCases}
            {enabledCases && ongoingCases}
            {experienceCustomization}
        </VStack>
    );
};

export default Dashboard;
