import { DevelopmentPropertyType } from "../../../../../common/vars/valuationsAndMembershipTypes/developmentPropertyTypes";

type TranslationNSObject = {
    [key in DevelopmentPropertyType]: string;
};

type Translations = {
    de: TranslationNSObject;
    en: TranslationNSObject;
};

export const developmentPropertyTypeTranslations: Translations = {
    de: {
        Commercial: "Gewerblich",
        "Other Development": "Sonstige Erschließung",
        Residential: "Wohnwirtschaftlich",
        Rural: "Ländlich",
        "Specialist and Trade": "Spezialist und Handel",
    },
    en: {
        Commercial: "Commercial",
        "Other Development": "Other Development",
        Residential: "Residential",
        Rural: "Rural",
        "Specialist and Trade": "Specialist and Trade",
    },
};
