import { CheckCircleIcon } from "@chakra-ui/icons";
import { Box, Flex, Input, Spacer, VStack } from "@chakra-ui/react";
import {
    APIProvider,
    Map,
    Marker,
    useMarkerRef,
} from "@vis.gl/react-google-maps";
import { useField, useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGoogleGeocode } from "../../utils/api/hooks";
import { CountrySelect } from "../inputs";
import { WvwText, WVWTitle } from "../components/typography";
import { WvwButton } from "../components/inputs";

const { REACT_APP_GOOGLEMAPS_API_KEY = "" } = process.env;

type PropTypes = {
    cityName: string;
    countryName: string;
    streetName: string;
    postalCodeName: string;
    latName: string;
    lngName: string;
    mapTitle: string;
    mapInstruction: string;
    w?: string;
};

const FormikAddressInputWithMap = (props: PropTypes) => {
    const {
        cityName,
        countryName,
        streetName,
        postalCodeName,
        latName,
        lngName,
        mapTitle,
        mapInstruction,
        w,
    } = props;

    const { t } = useTranslation("formik");

    const [markerRef] = useMarkerRef();

    const context = useFormikContext();

    const [zoom, setZoom] = useState<number>(12);

    const [streetField, streetMeta] = useField(streetName);
    const [postalCodeField, postalCodeMeta] = useField(postalCodeName);
    const [cityField, cityMeta] = useField(cityName);
    const [countryField, countryMeta] = useField(countryName);
    const [latField, latMeta] = useField(latName);
    const [lngField] = useField(lngName);

    const [location, setLocation] = useState<{
        confirmed: boolean;
        lat?: number;
        lng?: number;
    }>({
        // Set confirmed if initial lat long values are provided
        confirmed: latField.value && lngField.value,
        lat: latField.value ? Number(latField.value) : undefined,
        lng: lngField.value ? Number(lngField.value) : undefined,
    });

    const [addressModified, setAddressModified] = useState(true);

    const { update: geocodeAddress } = useGoogleGeocode({
        onSuccess: (data) => {
            if (data.results[0]) {
                const result = data.results[0];

                const { lat, lng } = result.geometry.location;

                setLocation({
                    lat,
                    lng,
                    confirmed: false,
                });

                setZoom(16);
            }
        },
    });

    useEffect(() => {
        if (location.confirmed) {
            latField.onChange({
                target: {
                    name: latName,
                    value: location.lat,
                },
            });

            lngField.onChange({
                target: {
                    name: lngName,
                    value: location.lng,
                },
            });

            context.setErrors({
                ...context.errors,
                [latName]: undefined,
                [lngName]: undefined,
            });
        } else {
            latField.onChange({
                target: {
                    name: latName,
                    value: undefined,
                },
            });

            lngField.onChange({
                target: {
                    name: lngName,
                    value: undefined,
                },
            });
        }
    }, [location]);

    return (
        <VStack
            width={w}
            alignItems="stretch"
            spacing="4"
        >
            <Flex
                width="100%"
                flexWrap="wrap"
                rowGap={2}
                justifyContent="space-between"
            >
                <Box
                    w="100%"
                    maxWidth={{ lg: "19%" }}
                >
                    <Input
                        bg="white"
                        h="3rem"
                        onBlur={() => context.setFieldTouched(streetName, true)}
                        onChange={(e) => {
                            streetField.onChange({
                                target: {
                                    name: streetName,
                                    value: e.target.value,
                                },
                            });
                            setAddressModified(true);
                        }}
                        placeholder={t("fieldPlaceholder.street", {
                            ns: "common",
                        })}
                        value={streetField.value}
                    />

                    {streetMeta.error && streetMeta.touched && (
                        <WvwText color="red">
                            {t(streetMeta.error, {
                                defaultValue: streetMeta.error,
                                ns: "formik",
                            })}
                        </WvwText>
                    )}
                </Box>

                <Spacer />

                <Box
                    w="100%"
                    maxWidth={{ lg: "19%" }}
                >
                    <Input
                        bg="white"
                        h="3rem"
                        onBlur={() =>
                            context.setFieldTouched(postalCodeName, true)
                        }
                        onChange={(e) => {
                            postalCodeField.onChange({
                                target: {
                                    name: postalCodeName,
                                    value: e.target.value,
                                },
                            });
                            setAddressModified(true);
                        }}
                        placeholder={t("fieldPlaceholder.postalCode", {
                            ns: "common",
                        })}
                        value={postalCodeField.value}
                        w="100%"
                    />

                    {postalCodeMeta.error && postalCodeMeta.touched && (
                        <WvwText color="red">
                            {t(postalCodeMeta.error, {
                                defaultValue: postalCodeMeta.error,
                                ns: "formik",
                            })}
                        </WvwText>
                    )}
                </Box>

                <Spacer />

                <Box
                    w="100%"
                    maxWidth={{ lg: "19%" }}
                >
                    <Input
                        bg="white"
                        h="3rem"
                        onBlur={() => context.setFieldTouched(cityName, true)}
                        onChange={(e) => {
                            cityField.onChange({
                                target: {
                                    name: cityName,
                                    value: e.target.value,
                                },
                            });
                            setAddressModified(true);
                        }}
                        placeholder={t("fieldPlaceholder.city", {
                            ns: "common",
                        })}
                        value={cityField.value}
                        w="100%"
                    />

                    {cityMeta.error && cityMeta.touched && (
                        <WvwText color="red">
                            {t(cityMeta.error, {
                                defaultValue: cityMeta.error,
                                ns: "formik",
                            })}
                        </WvwText>
                    )}
                </Box>

                <Spacer />

                <Box
                    w="100%"
                    maxWidth={{ lg: "19%" }}
                >
                    <CountrySelect
                        name="country"
                        onBlur={() =>
                            context.setFieldTouched(countryName, true)
                        }
                        onChange={(e) => {
                            countryField.onChange({
                                target: {
                                    name: countryName,
                                    value: e.target.value,
                                },
                            });
                            setAddressModified(true);
                        }}
                        placeholder={t("fieldPlaceholder.country", {
                            ns: "common",
                        })}
                        value={countryField.value}
                    />

                    {countryMeta.error && countryMeta.touched && (
                        <WvwText color="red">
                            {t(countryMeta.error, {
                                defaultValue: countryMeta.error,
                                ns: "formik",
                            })}
                        </WvwText>
                    )}
                </Box>

                <Spacer />

                <Box
                    w="100%"
                    maxWidth={{ lg: "19%" }}
                >
                    <WvwButton
                        width="100%"
                        isDisabled={
                            streetField.value === "" ||
                            postalCodeField.value === "" ||
                            cityField.value === "" ||
                            countryField.value === "" ||
                            !addressModified
                        }
                        onClick={() => {
                            geocodeAddress({
                                street: streetField.value,
                                postalCode: postalCodeField.value,
                                city: cityField.value,
                                country: countryField.value,
                            });
                            setAddressModified(false);
                            context.setFieldTouched(latName, false);
                            context.setFieldTouched(lngName, false);
                        }}
                        size="lg"
                        content={t("button.locateAddress", { ns: "common" })}
                    />
                </Box>
            </Flex>

            <Flex
                width="100%"
                alignItems="center"
                flexWrap="wrap"
            >
                <VStack
                    align="left"
                    width="100%"
                    maxWidth={{ md: "60%" }}
                >
                    <WVWTitle
                        level="2"
                        color="black"
                        content={mapTitle}
                    />

                    <WvwText>{mapInstruction}</WvwText>
                </VStack>

                <Spacer />

                <Flex
                    width="100%"
                    maxWidth={{ md: "40%", lg: "30%" }}
                    gap={2}
                    flexWrap="wrap"
                    justifyContent="flex-end"
                >
                    <WvwButton
                        block
                        isDisabled={
                            streetField.value === "" ||
                            postalCodeField.value === "" ||
                            cityField.value === "" ||
                            countryField.value === "" ||
                            !latMeta.touched
                        }
                        onClick={() => {
                            geocodeAddress({
                                street: streetField.value,
                                postalCode: postalCodeField.value,
                                city: cityField.value,
                                country: countryField.value,
                            });
                            context.setFieldTouched(latName, false);
                            context.setFieldTouched(lngName, false);
                        }}
                        content={t("mapInputInstructions.resetToAddress", {
                            ns: "formik",
                        })}
                    />

                    <WvwButton
                        block
                        icon={
                            location.confirmed ? <CheckCircleIcon /> : undefined
                        }
                        isDisabled={
                            !location.lat ||
                            !location.lng ||
                            streetField.value === "" ||
                            postalCodeField.value === "" ||
                            cityField.value === "" ||
                            countryField.value === ""
                        }
                        onClick={() => {
                            setLocation({
                                ...location,
                                confirmed: true,
                            });
                        }}
                        variant="primary"
                        content={
                            location.confirmed
                                ? t("mapInputInstructions.locationConfirmed", {
                                      ns: "formik",
                                  })
                                : t("button.confirmLocation", { ns: "common" })
                        }
                    />
                </Flex>
            </Flex>

            <Box
                h="40vh"
                w="100%"
            >
                <APIProvider apiKey={REACT_APP_GOOGLEMAPS_API_KEY}>
                    <Map
                        center={{
                            lat: location.lat || 53.54992,
                            lng: location.lng || 10.00678,
                        }}
                        onClick={(event) => {
                            const latLng = JSON.parse(
                                JSON.stringify(event.detail.latLng)
                            );

                            setLocation({
                                ...latLng,
                                confirmed: false,
                            });

                            context.setFieldTouched(latName, true);
                            context.setFieldTouched(lngName, true);
                        }}
                        onZoomChanged={(event) => setZoom(event.detail.zoom)}
                        zoom={zoom}
                    >
                        {location.lat && location.lng && (
                            <Marker
                                ref={markerRef}
                                position={{
                                    lat: location.lat || 53.54992,
                                    lng: location.lng || 10.00678,
                                }}
                            />
                        )}
                    </Map>
                </APIProvider>
            </Box>

            {latMeta.error && !location.confirmed && (
                <WvwText color="red">
                    {t("errors.mapRequired", {
                        defaultValue: "errors.mapRequired",
                        ns: "formik",
                    })}
                </WvwText>
            )}
        </VStack>
    );
};

FormikAddressInputWithMap.defaultProps = {
    w: "100%",
};

export default FormikAddressInputWithMap;
