import {
    ButtonGroup, Flex, Heading, HStack, IconButton,
    Text
} from "@chakra-ui/react";
import { ArrowRight2 } from "iconsax-react";
import { Link as ReactLink } from "react-router-dom";
import delimitNumber from "../../../../../../common/functions/delimitNumber";
import { CaseType } from "../../../../../../types";
import { Case } from "../../../../domain";
import PublicUserProfilePictureWithEntityLogo from "../../PublicUserProfilePictureWithEntityLogo";
import { DataTableColumnType } from "../DataTable";
import { estimatePortfolioValue } from "../TableDataCalculations";

const caseTableNameSpace = "common.components.dataTable.columns.caseColumns";

type Column = DataTableColumnType<CaseType>;

export const caseIdWithProperties: Column = {
    title: (t) => t(`${caseTableNameSpace}.case`),
    render: (i, t) => {
        const firstProperty = `${i.enquiry.properties[0].city}, ${i.enquiry.properties[0].street}`;
        const noOfProperties = i.enquiry.properties.length;

        return (
            <Flex
                direction="column"
                gap={0}
            >
                <Text
                    color="gray.900"
                    fontWeight={700}
                >
                    {i.id}
                </Text>
                <Text
                    color="gray.500"
                    fontSize={14}
                >
                    {firstProperty}
                </Text>
                {noOfProperties > 1 ? (
                    <Text
                        color="gray.500"
                        fontSize={14}
                    >
                        {t(`${caseTableNameSpace}.moreText`, {
                            count: noOfProperties - 1,
                        })}
                    </Text>
                ) : (
                    ""
                )}
            </Flex>
        );
    },
    sorter: (a, b) => a.id - b.id,
};

export const caseClient: Column = {
    title: (t) => t(`${caseTableNameSpace}.client`),
    render: (i, t) => {
        const {
            id: clientId,
            entityId,
            firstName,
            lastName,
            orgPosition,
            workingForCompanyName,
        } = i.clientUser;

        const clientName = `${firstName} ${lastName}`;

        return (
            <HStack spacing="3">
                <PublicUserProfilePictureWithEntityLogo
                    entityId={entityId}
                    userId={clientId}
                />

                <Flex direction="column">
                    <Heading size="sm">{clientName}</Heading>

                    <Text variant="secondary">
                        {orgPosition &&
                            t(`${caseTableNameSpace}.position.at`, {
                                position: orgPosition,
                                company: workingForCompanyName,
                                interpolation: { escapeValue: false },
                            })}

                        {!orgPosition && workingForCompanyName}
                    </Text>
                </Flex>
            </HStack>
        );
    },
    sorter: (a, b) => {
        const aName = `${a.clientUser.firstName} ${a.clientUser.lastName}`;
        const bName = `${b.clientUser.firstName} ${b.clientUser.lastName}`;

        return aName.localeCompare(bName);
    },
};

export const valuationType: Column = {
    title: (t) => t(`${caseTableNameSpace}.type`),
    render: (i) => {
        const { valuationType, valuationStandards } = i.enquiry;
        return (
            <Flex direction="column">
                <Heading size="sm">{valuationType}</Heading>
                <Text variant="secondary">{valuationStandards}</Text>
            </Flex>
        );
    },
};

export const offerPiCover: Column = {
    title: (t) => t(`${caseTableNameSpace}.piCover`),
    render: (i) => {
        const { piCoverValue, piCoverPercentage } = i.acceptedOffer;

        const coverValue = `€${delimitNumber(piCoverValue)}`;

        return (
            <Flex direction="column">
                <Heading size="sm">{coverValue}</Heading>
                <Text variant="secondary">{`${piCoverPercentage}%`}</Text>
            </Flex>
        );
    },
};

export const expectedDelivery: Column = {
    alignment: "right",
    title: (t) => t(`${caseTableNameSpace}.expectedDelivery`),
    render: (caseData, t) => {
        const { deliveryDate, daysToDeliveryDateText } =
            Case.getAgreedDeliveryDate(caseData, t);

        return (
            <Flex
                alignItems="end"
                direction="column"
            >
                <Heading size="sm">{deliveryDate}</Heading>
                <Text variant="secondary">{daysToDeliveryDateText}</Text>
            </Flex>
        );
    },
    sorter: (a, b) =>
        new Date(a.enquiry.completionDate).getTime() -
        new Date(b.enquiry.completionDate).getTime(),
};

export const estPortfolioValue: Column = {
    alignment: "right",
    title: (t) => t(`${caseTableNameSpace}.portfolioValue`),
    render: (i) => {
        const { properties } = i.enquiry;
        const estPortfolioValue = estimatePortfolioValue(properties);

        const delimitedValue = `€${delimitNumber(estPortfolioValue)}`;

        return (
            <Heading
                size="sm"
                color="cyan.500"
            >
                {delimitedValue}
            </Heading>
        );
    },
    sorter: (a, b) => {
        const aEstPortfolioValue = estimatePortfolioValue(a.enquiry.properties);
        const bEstPortfolioValue = estimatePortfolioValue(b.enquiry.properties);

        return aEstPortfolioValue - bEstPortfolioValue;
    },
};

export const viewCaseButton: Column = {
    alignment: "right",
    render: (i) => (
        <ButtonGroup>
            <IconButton
                as={ReactLink}
                to={`/dashboard/case/${i.id}`}
                variant="ghost"
                icon={<ArrowRight2 />}
                aria-label="arrow"
            />
        </ButtonGroup>
    ),
};

export const caseValuer: Column = {
    title: (t) => t(`${caseTableNameSpace}.selectedValuer`),
    render: (i, t) => {
        const {
            id: valuerId,
            entityId,
            firstName,
            lastName,
            orgPosition,
            workingForCompanyName,
        } = i.valuerUser;

        const valuerName = `${firstName} ${lastName}`;

        return (
            <HStack spacing="3">
                <PublicUserProfilePictureWithEntityLogo
                    entityId={entityId}
                    userId={valuerId}
                />

                <Flex direction="column">
                    <Heading size="sm">{valuerName}</Heading>

                    <Text variant="secondary">
                        {orgPosition &&
                            t(`${caseTableNameSpace}.position.at`, {
                                position: orgPosition,
                                company: workingForCompanyName,
                                interpolation: { escapeValue: false },
                            })}

                        {!orgPosition && workingForCompanyName}
                    </Text>
                </Flex>
            </HStack>
        );
    },
    sorter: (a, b) => {
        const aName = `${a.valuerUser.firstName} ${a.valuerUser.lastName}`;
        const bName = `${b.valuerUser.firstName} ${b.valuerUser.lastName}`;

        return aName.localeCompare(bName);
    },
};

export const caseColumns: { [index: string]: Column } = {
    caseIdWithProperties,
    caseClient,
    valuationType,
    offerPiCover,
    expectedDelivery,
    estPortfolioValue,
    viewCaseButton,
    caseValuer,
};
