import { createContext } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { fromAddress } from "react-geocode";
import { MeUpdateDto } from "../../../utils/api/dto";
import { meGet, meUpdate } from "../../../utils/api/endpoints";
import { auth } from "../../../utils/firebase";
import storage from "../../../utils/storage/storage";
import { User } from "../domain";
import useQueryHook from "../../../utils/api/hooks/useQueryHook";
import useMutationHook from "../../../utils/api/hooks/useMutationHook";

export const UserContext = createContext<User | undefined>(undefined);

type ParamsType = {
    onUpdateSuccess?: () => void;
};

const ME = "me";
const MY_CERTIFICATES = "MY_CERTIFICATES";
const MY_PROFILE_PICTURE = "MY_PROFILE_PICTURE";

const useMe = (params: ParamsType = {}) => {
    const { onUpdateSuccess } = params;

    const [user, loadingFBuser, fbError] = useAuthState(auth);

    const { data, error, isLoading, refresh } = useQueryHook({
        uniqueName: ME,
        dependancies: [!!user],
        requiredParams: [!!user],
        queryFunction: meGet,
    });

    const userId = data?.id;
    const hasUserId = !!user;

    const {
        data: profilePictureUrl,
        error: profilePictureUrlError,
        isLoading: profilePictureUrlIsLoading,
        refresh: profilePictureRefresh,
    } = useQueryHook({
        uniqueName: MY_PROFILE_PICTURE,
        dependancies: [hasUserId],
        requiredParams: [hasUserId],
        queryFunction: async () => {
            if (!userId) return "";

            const profilePicture = await storage.getUserProfilePictureUrl(
                userId
            );

            if (!profilePicture) {
                return "";
            }

            return profilePicture;
        },
    });

    const {
        data: myCertificates,
        error: myCertificatesError,
        isLoading: myCertificatesIsLoading,
        refresh: myCertificatesRefresh,
    } = useQueryHook({
        uniqueName: MY_CERTIFICATES,
        dependancies: [hasUserId],
        requiredParams: [hasUserId],
        queryFunction: async () => {
            if (!userId) return [];

            const certificateList = await storage.getUserCertificates(userId);

            return certificateList || [];
        },
    });

    const { update: updateMe } = useMutationHook({
        mutationFunction: async (updates: MeUpdateDto) => {
            const { city, country, postalCode, street } = updates;

            const dto = {
                ...data,
                ...updates,
                id: Number(data?.id),
            };

            if (street || city || country || postalCode) {
                const address = `${updates.street}, ${updates.city}, ${updates.country}, ${updates.postalCode}`;

                const location = await fromAddress(address).then(
                    (response) => response.results[0].geometry.location
                );

                dto.latitude = location.lat.toString();
                dto.longitude = location.lng.toString();
            }

            return meUpdate(dto);
        },
        refreshes: [refresh],
        onSuccess: onUpdateSuccess,
    });

    const stillLoading = isLoading || loadingFBuser;

    return {
        data:
            data && user
                ? ({
                      ...data,
                      emailVerified: user.emailVerified,
                  } as User)
                : undefined,
        error,
        fbError,
        isLoading: stillLoading,
        refresh,

        profilePictureRefresh,
        profilePictureUrl,
        profilePictureUrlError,
        profilePictureUrlIsLoading,

        myCertificates,
        myCertificatesError,
        myCertificatesIsLoading,
        myCertificatesRefresh,

        updateMe,
    };
};

export default useMe;
