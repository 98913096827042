export const translations = {
    de: {
        button: {
            complete: "Vervollständigen",
        },
        customizeExperience: {
            customizeYourExperience: "Passe deine Erfahrung an",
            completeProfile: {
                title: "Vervollständige dein Profil",
                subtext:
                    "Es fehlen Pflichtangaben zu Ihrem Unternehmen, bitte füllen Sie alle relevanten Felder aus.",
            },
            addTeamMembers: {
                title: "Teammitglieder hinzufügen",
                subtext:
                    "Fügen Sie gegebenenfalls Teammitglieder zu Ihrem Unternehmensprofil hinzu.",
            },
        },
        noDataPlaceholder: "Noch keine Anfragen erstellt.",
        noOfEntries: "Anzeigen",
        ongoingCasesWithCount: "Laufende Geschäftsfälle: {{count}}",
        recentEnquiriesWithCount: "Aktuelle Anfragen: {{count}}",
        statistics: {
            cases: {
                active: "Aktiv",
                completed: "Abgeschlossen",
            },
            enquiries: {
                active: "Aktiv",
                avgOffers: "Durchschn. Angebote",
                avgSelectionTime: "Durchschn. Auswahlzeit",
                days: "{{days}} Tage",
                hours: "{{hours}}h ",
                minutes: "{{minutes}} min",
                overAMonth: "1 Monat+",
            },
        },
    },
    en: {
        button: {
            complete: "Complete",
        },
        customizeExperience: {
            customizeYourExperience: "Customize your experience",
            completeProfile: {
                title: "Complete your profile",
                subtext:
                    "You have missing mandatory information about your company, please complete all relevant fields.",
            },
            addTeamMembers: {
                title: "Add team members",
                subtext:
                    "Consider adding your team members to your company profile.",
            },
        },
        noDataPlaceholder: "No enquiries created yet.",
        noOfEntries: "Show",
        ongoingCasesWithCount: "Ongoing Cases: {{count}}",
        recentEnquiriesWithCount: "Recent Enquiries: {{count}}",
        statistics: {
            cases: {
                active: "Active",
                completed: "Completed",
            },
            enquiries: {
                active: "Active",
                avgOffers: "Avg. Offers",
                avgSelectionTime: "Avg. selection time",
                days: "{{days}} Days",
                hours: "{{hours}}h ",
                minutes: "{{minutes}} min",
                overAMonth: "1 month+",
            },
        },
    },
};
