import { Box } from '@chakra-ui/react';
import { t } from 'i18next';
import { EntityType, UserType } from '../../../../types';
import delimitNumber from '../../../functions/delimitNumber';
import StatusBadgeOffer from '../../display/StatusBadgeOffer';
import { WvwButtonAsReactLink } from '../../inputs';
import { WvwText } from '../../typography';

/*
FIXME "deep indexing" possible?
e.g. valuer-column: could include only firstName and lastName instead of the whole object
*/

type HasFromEntity = {
    fromEntity: EntityType;
};

export const company = {
    title: t('offerTable.header.company', { ns: 'dashboard' }),
    render: (i: HasFromEntity) => i.fromEntity.name,
    sorter: (a: HasFromEntity, b: HasFromEntity) => (
        a.fromEntity.name.localeCompare(b.fromEntity.name)
    ),
};

type HasFromValuer = {
    fromValuer: UserType;
};

export const valuer = {
    title: t('offerTable.header.valuer', { ns: 'dashboard' }),
    dataIndex: 'valuer',
    render: (i: HasFromValuer) => `${i.fromValuer.firstName} ${i.fromValuer.lastName}`,
    sorter: (a: HasFromValuer, b: HasFromValuer) => {
        const aName = `${a.fromValuer.firstName} ${a.fromValuer.lastName}`;
        const bName = `${b.fromValuer.firstName} ${b.fromValuer.lastName}`;

        return aName.localeCompare(bName);
    },
};

export type HasPrice = {
    price: number;
};

export const price = {
    title: t('offerTable.header.price', { ns: 'dashboard' }),
    dataIndex: 'price',
    sorter: (a: HasPrice, b: HasPrice) => a.price - b.price,
    render: (i: HasPrice) => (
        <WvwText>
            {`${t('unitSymbol.currency', { ns: 'common' })} ${delimitNumber(i.price)}`}
        </WvwText>
    ),
};

export type HasAvailability = {
    availability: string;
};

export const availability = {
    title: t('offerTable.header.availability', { ns: 'dashboard' }),
    dataIndex: 'availability',
    sorter: (a: HasAvailability, b: HasAvailability) => {
        if (b.availability.replace(/-/g, '') < a.availability.replace(/-/g, '')) {
            return -1;
        }
        if (b.availability.replace(/-/g, '') > a.availability.replace(/-/g, '')) {
            return 1;
        }
        return 0;
    },
};

type HasStatus = {
    status: 'PENDING' | 'ACCEPTED' | 'REJECTED' | 'WITHDRAWN';
};

export const status = {
    title: t('offerTable.header.status', { ns: 'dashboard' }),
    dataIndex: 'status',
    render: (i: HasStatus) => <StatusBadgeOffer status={i.status} />,
    sorter: (a: HasStatus, b: HasStatus) => a.status.localeCompare(b.status),
};

type HasPICover = {
    piCoverCurrency?: string;
    piCoverPercentage?: number;
    piCoverValue?: number;
};

export const piCover = {
    title: t('heading.piCover', { ns: 'account' }),
    dataIndex: 'piCoverValue',
    render: (i: HasPICover) => (
        <WvwText>
            {i.piCoverValue ? `${i.piCoverCurrency ? i.piCoverCurrency : `${t('unitSymbol.currency', { ns: 'common' })}`} ${delimitNumber(i.piCoverValue)}` : `${i.piCoverPercentage}%`}
        </WvwText>
    ),
    sorter: (a: HasPICover, b: HasPICover) => {
        if (a.piCoverValue && b.piCoverValue) {
            return a.piCoverValue - b.piCoverValue;
        }
        if (a.piCoverPercentage && b.piCoverPercentage) {
            return a.piCoverPercentage - b.piCoverPercentage;
        }
        return 0;
    },
};

type HasIdAndEnquiryId = {
    enquiryId?: number;
    id: number;
};

export const viewButton = {
    render: (i: HasIdAndEnquiryId) => (
        <Box display="flex" justifyContent="flex-end" width="100%" paddingRight="1.2rem">
            <WvwButtonAsReactLink
                to={`/dashboard/enquiry/${i.enquiryId}/offer/${i.id}`}
                content={t('button.view', { ns: 'common' })}
                variant="link"
            />
        </Box>
    ),
};

const offerColumns = {
    company,
    valuer,
    price,
    availability,
    status,
    piCover,
    viewButton,
};

export default offerColumns;
