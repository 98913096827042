import { PropertyType } from "../../../../../../types";
import {
    GoogleMapV2,
    MapMarkerType,
} from "../../../../common/components/display";

type Props = {
    properties: PropertyType[];
    selectedProperty?: PropertyType;
};

export const PropertiesMap = (props: Props) => {
    const { properties, selectedProperty } = props;

    let selectedMarker: MapMarkerType | undefined = undefined;

    if (selectedProperty) {
        selectedMarker = {
            id: selectedProperty.id,
            lat: Number(selectedProperty.latitude),
            lng: Number(selectedProperty.longitude),
        };
    }

    const markers: MapMarkerType[] = properties.map((i) => ({
        id: i.id,
        lat: Number(i.latitude),
        lng: Number(i.longitude),
    }));

    return (
        <GoogleMapV2
            height="30rem"
            markers={markers}
            selectedMarker={selectedMarker}
        />
    );
};
