import { StandardPropertyCondition } from "../../../../../common/vars/valuationsAndMembershipTypes/standardPropertyConditions";

type TranslationNSObject = {
    [key in StandardPropertyCondition]: string;
};

type Translations = {
    de: TranslationNSObject;
    en: TranslationNSObject;
};

export const propertyConditionTranslations: Translations = {
    de: {
        DATED: "Veraltet",
        NEW: "Neu",
        RECENTLY_REFURBISHED: "Kürzlich renoviert",
    },
    en: {
        DATED: "Dated",
        NEW: "New",
        RECENTLY_REFURBISHED: "Recently refurbished",
    },
};
