export type ResidentialPropertyHouseType =
    (typeof residentialPropertyHouseTypes)[number];

const residentialPropertyHouseTypes = [
    "Terraced",
    "End-Terraced",
    "Semi-Detached",
    "Detached",
    "Bungalow",
] as const;

export default residentialPropertyHouseTypes;
