export const translations = {
    de: {
        awaitingApprovalBlurbOne:
            "Bitte beachten Sie, dass Ihr Konto noch auf Freischaltung wartet.",
        awaitingApprovalBlurbTwo:
            "Sie erhalten Zugriff auf alle Funktionen, sobald Ihr Konto freigeschaltet wurde.",
    },
    en: {
        awaitingApprovalBlurbOne:
            "Please note your account is awaiting approval.",
        awaitingApprovalBlurbTwo:
            "You will receive access to all features as soon as you have been approved.",
    },
};
