export type ResidentialPropertyFlatType =
    (typeof residentialPropertyFlatTypes)[number];

const residentialPropertyFlatTypes = [
    "Multi-Unit",
    "First Floor",
    "Apartment",
    "Top Floor",
    "Maisonette",
    "Penthouse",
] as const;

export default residentialPropertyFlatTypes;
