import { calculateMinutesBetweenDates } from "../../../../../../common/functions";
import { OfferOnlyType, CaseType, EnquiryType } from "../../../../../../types";
import { StatsType } from "../../../../common/features/statistics/Statistics";
import { TranslationFunction } from "../../../../common/hooks/useSmartTranslation";

export const activeEnquiriesStatistic = (
    enquiries: EnquiryType[]
): StatsType => {
    const activeEnquiriesCount = enquiries.filter(
        (enquiry) => enquiry.status === "PENDING"
    ).length;

    return {
        title: (t: TranslationFunction) =>
            t("clientDashboard.statistics.enquiries.active"),
        data: activeEnquiriesCount,
        key: "clientDashboard.statistics.enquiries.active",
    };
};

export const clientAverageOffersStatistic = (
    enquiries: EnquiryType[],
    offers: OfferOnlyType[]
): StatsType => {
    const enquiriesCount = enquiries.length;
    const offersCount = offers.length;

    const averageOffers = Number((offersCount / enquiriesCount).toFixed(2));

    return {
        title: (t: TranslationFunction) =>
            t("clientDashboard.statistics.enquiries.avgOffers"),
        data: averageOffers,
        key: "clientDashboard.statistics.enquiries.avgOffers",
    };
};

export const averageSelectionTimeStatistic = (
    t: TranslationFunction,
    cases: CaseType[],
    offers: OfferOnlyType[]
): StatsType => {
    if (cases.length === 0) {
        return {
            title: (t: TranslationFunction) =>
                t("clientDashboard.statistics.enquiries.avgSelectionTime"),
            data: undefined,
            key: "clientDashboard.statistics.enquiries.avgSelectionTime",
        };
    }

    const selectionTimes = cases.map((caseData) => {
        const { enquiryId, createdAt } = caseData;
        const offersPerEnquiry = offers
            .filter((offer) => {
                return offer.enquiryId === enquiryId;
            })
            .sort((a, b) => {
                const aDate = new Date(a.createdAt);
                const bDate = new Date(b.createdAt);
                return aDate.getTime() - bDate.getTime();
            });

        const firstOfferDate = new Date(offersPerEnquiry[0].createdAt);
        const caseCreationDate = new Date(createdAt);
        return calculateMinutesBetweenDates(firstOfferDate, caseCreationDate);
    });

    const numberOfCases = cases.length;
    const totalOffersTime = selectionTimes.reduce((total, offersTime) => {
        return total + offersTime;
    }, 0);

    const averageOfferTime = Math.floor(totalOffersTime / numberOfCases);

    const formattedTime = (minutes: number): string => {
        if (minutes < 60) {
            return t("clientDashboard.statistics.enquiries.minutes", {
                minutes,
            });
        } else if (minutes < 5040) {
            const hours = Math.floor(minutes / 60);
            const remainingMinutes = minutes % 60;
            const hoursTranslation = t(
                "clientDashboard.statistics.enquiries.hours",
                { hours }
            );
            const minutesTranslation = t(
                "clientDashboard.statistics.enquiries.minutes",
                { minutes: remainingMinutes }
            );
            return `${hoursTranslation} ${minutesTranslation}`;
        } else if (minutes < 44640) {
            const days = Math.round(minutes / 1440);
            return t("clientDashboard.statistics.enquiries.days", { days });
        } else {
            return t("clientDashboard.statistics.enquiries.overAMonth");
        }
    };

    return {
        title: (t: TranslationFunction) =>
            t("clientDashboard.statistics.enquiries.avgSelectionTime"),
        data: formattedTime(averageOfferTime),
        key: "clientDashboard.statistics.enquiries.avgSelectionTime",
    };
};

export const activeCasesStatistic = (cases: CaseType[]): StatsType => {
    const activeCasesCount = cases.filter(
        (casedata) => casedata.status === "PENDING"
    ).length;

    return {
        title: (t: TranslationFunction) =>
            t("clientDashboard.statistics.cases.active"),
        data: activeCasesCount,
        key: "clientDashboard.statistics.cases.active",
    };
};

export const completedCasesStatistic = (cases: CaseType[]): StatsType => {
    const activeCasesCount = cases.filter(
        (casedata) => casedata.status === "COMPLETED"
    ).length;

    return {
        title: (t: TranslationFunction) =>
            t("clientDashboard.statistics.cases.completed"),
        data: activeCasesCount,
        key: "clientDashboard.statistics.cases.completed",
    };
};
