import { StandardMaxOfficeDistance } from "../../../../../common/vars/valuationsAndMembershipTypes/standardMaxOfficeDistance";

type TranslationNSObject = {
    [key in StandardMaxOfficeDistance]: string;
};

type Translations = {
    de: TranslationNSObject;
    en: TranslationNSObject;
};

export const maxOfficeDistanceTranslations: Translations = {
    de: {
        none: "Keine Angabe",
        "5": "Weniger als 5 Kilometer",
        "10": "Weniger als 10 Kilometer",
        "20": "Weniger als 20 Kilometer",
        "50": "Weniger als 50 Kilometer",
        "100": "Weniger als 100 Kilometer",
    },
    en: {
        none: "No Requirement",
        "5": "Less than 5 Kilometers",
        "10": "Less than 10 Kilometers",
        "20": "Less than 20 Kilometers",
        "50": "Less than 50 Kilometers",
        "100": "Less than 100 Kilometers",
    },
};
