import { t } from "i18next";
import { useState } from "react";
import ContactForm from "../../../common/components/ContactForm";
import ModalDynamic from "../../../common/components/ModalDynamic";
import { ThemeV1Wrapper } from "../../../ui/v2/app/ThemeV1Wrapper";

const useSupportModal = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const modal = (
        <ThemeV1Wrapper>
            <ModalDynamic
                variant="medium"
                isOpen={modalIsOpen}
                header={t("support", { ns: "common" })}
                onClose={() => setModalIsOpen(false)}
            >
                <ContactForm />
            </ModalDynamic>
        </ThemeV1Wrapper>
    );

    return {
        modal,
        openModal: () => setModalIsOpen(true),
    };
};

export default useSupportModal;
