import { StandardPropertyType } from "../../../../../common/vars/valuationsAndMembershipTypes/standardPropertyTypes";

type TranslationNSObject = {
    [key in StandardPropertyType]: string;
};

type Translations = {
    de: TranslationNSObject;
    en: TranslationNSObject;
};

export const propertyTypeTranslations: Translations = {
    de: {
        Agricultural: "Landwirtschaft",
        Commercial: "Gewerblich",
        Development: "Bauvorhaben",
        Land: "Grundstück",
        Residential: "Wohnwirtschaftlich",
        Special: "Besonderheiten",
    },
    en: {
        Agricultural: "Agricultural",
        Commercial: "Commercial",
        Development: "Development",
        Land: "Land",
        Residential: "Residential",
        Special: "Special",
    },
};
