import { DownloadIcon, Search2Icon } from "@chakra-ui/icons";
import { Flex, IconButton, Text, useDisclosure } from "@chakra-ui/react";
import { Folder } from "iconsax-react";
import { useState } from "react";
import { Link as ReactLink } from "react-router-dom";
import { PropertyDocumentType } from "../../../../../../types";
import ModalDynamic from "../../../../common/components/ModalDynamic";

type PropertyDocumentItemProps = {
    documentItem: PropertyDocumentType;
    viewDocument: () => void;
};

const PropertyDocumentListItem = (props: PropertyDocumentItemProps) => {
    const { documentItem, viewDocument } = props;

    return (
        <>
            <Flex
                gap={3}
                align="center"
            >
                <Folder size={20} />
                <Text
                    fontSize={14}
                    color="gray.900"
                >
                    {documentItem.name}
                </Text>
            </Flex>
            <Flex
                gap={2}
                align="center"
            >
                {/* TODO: display file size when Michael add this in to a BE side */}
                <Text
                    fontSize={12}
                    color="gray.400"
                >
                    2.2 MB
                </Text>
                <IconButton
                    icon={<Search2Icon />}
                    size="xs"
                    variant="ghost"
                    color="gray.400"
                    aria-label="search"
                    onClick={viewDocument}
                />
                <IconButton
                    as={ReactLink}
                    to={documentItem.url}
                    icon={<DownloadIcon />}
                    size="xs"
                    variant="ghost"
                    color="gray.400"
                    aria-label="save"
                    download
                />
            </Flex>
        </>
    );
};

type PropertyDocumentsListProps = {
    documents: PropertyDocumentType[] | undefined;
};

const PropertyDocumentList = (props: PropertyDocumentsListProps) => {
    const { documents } = props;

    const { isOpen, onOpen, onClose } = useDisclosure();

    const [viewedDocument, setViewedDocument] =
        useState<PropertyDocumentType>();

    return (
        <>
            {documents?.map((item, i, arr) => (
                <Flex
                    key={item.name}
                    justify="space-between"
                    align="center"
                    padding=".5rem 1rem"
                    borderBottom={i !== arr.length - 1 ? "1px solid #fff" : ""}
                >
                    <PropertyDocumentListItem
                        documentItem={item}
                        viewDocument={() => {
                            setViewedDocument(item);
                            onOpen();
                        }}
                    />
                </Flex>
            ))}

            {viewedDocument && (
                <ModalDynamic
                    size="full"
                    isOpen={isOpen}
                    header={viewedDocument.name}
                    onClose={onClose}
                >
                    <Flex grow={1}>
                        <iframe
                            src={viewedDocument.url}
                            width="100%"
                            style={{ height: "calc(100vh - 70px)" }}
                        />
                    </Flex>
                </ModalDynamic>
            )}
        </>
    );
};

export default PropertyDocumentList;
