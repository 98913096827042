// FIXME remove once key is put in proper place
const MISSING_TRANSLATION = "MISSING_TRANSLATION";

export const translations = {
    de: {
        enquiryTitle: {
            detailsAvailableAfterOfferAcceptance:
                "Kundendetails verfügbar nach Angebotsannahme",
        },
        propertyPortfolio: {
            title: "Immobilien-Portfolio",
            summary: {
                ammountOfProperties: MISSING_TRANSLATION,
                avgPropertyArea: MISSING_TRANSLATION,
                biggest: MISSING_TRANSLATION,
                cheapest: MISSING_TRANSLATION,
                estimatePortfolioValue: MISSING_TRANSLATION,
                mostExpensive: MISSING_TRANSLATION,
                noOfProperties: MISSING_TRANSLATION,
                propertyTypes: {
                    Residential: MISSING_TRANSLATION,
                    Commercial: MISSING_TRANSLATION,
                    Land: MISSING_TRANSLATION,
                    Agricultural: MISSING_TRANSLATION,
                    Development: MISSING_TRANSLATION,
                    Special: MISSING_TRANSLATION,
                },
                smallest: MISSING_TRANSLATION,
            },
        },
        propertyDetails: {
            area: MISSING_TRANSLATION,
            buildYear: MISSING_TRANSLATION,
            condition: MISSING_TRANSLATION,
            documents: MISSING_TRANSLATION,
            estimateValue: MISSING_TRANSLATION,
            propertyType: MISSING_TRANSLATION,
            parking: MISSING_TRANSLATION,
            propertyCondition: MISSING_TRANSLATION,
            propertyTypes: {
                Residential: MISSING_TRANSLATION,
                Commercial: MISSING_TRANSLATION,
                Land: MISSING_TRANSLATION,
                Agricultural: MISSING_TRANSLATION,
                Development: MISSING_TRANSLATION,
                Special: MISSING_TRANSLATION,
            },
            specialFeatures: MISSING_TRANSLATION,
        },
        companyOffers: {
            title: "Angebote der Firma: {{nCompanyOffers}}",
        },
        myOffer: {
            title: "Mein Angebot",
        },
    },
    en: {
        enquiryTitle: {
            detailsAvailableAfterOfferAcceptance:
                "Client details available after offer acceptance",
        },
        propertyPortfolio: {
            title: "Property portfolio",
            summary: {
                ammountOfProperties: "Ammount of Properties",
                avgPropertyArea: "Avg. property area",
                biggest: "Biggest",
                cheapest: "Cheapest",
                estimatePortfolioValue: "Estimate portfolio value",
                mostExpensive: "Most expensive",
                noOfProperties: "{{number}} properties",
                propertyTypes: {
                    Residential: "Residential",
                    Commercial: "Commercial",
                    Land: "Land",
                    Agricultural: "Agricultural",
                    Development: "Development",
                    Special: "Special",
                },
                smallest: "Smallest",
            },
        },
        propertyDetails: {
            area: "Area",
            buildYear: "Build year",
            condition: "Condition",
            documents: "Supporting documents: {{count}}",
            estimateValue: "Est. Value",
            parking: "Parking",
            propertyType: "Property type",
            propertyCondition: MISSING_TRANSLATION,
            propertyTypes: {
                Residential: MISSING_TRANSLATION,
                Commercial: MISSING_TRANSLATION,
                Land: MISSING_TRANSLATION,
                Agricultural: MISSING_TRANSLATION,
                Development: MISSING_TRANSLATION,
                Special: MISSING_TRANSLATION,
            },
            specialFeatures: "Special features",
        },
        companyOffers: {
            title: "Company's Offers: {{nCompanyOffers}}",
        },
        myOffer: {
            title: "My Offer",
        },
    },
};
