export const translations = {
    de: {
        activeOffers: {
            footer: {
                apply: "Ausführen",
                selected: "Ausgewählt: {{count}}",
            },
            title: "Angebote: {{count}}",
        },
        offerHistory: {
            title: "Angebots-Historie",
            noExistingOfferHistory:
                "Keine zurückliegenden Angebote in Ihrer Historie.",
        },
        propertyPortfolio: {
            title: "Immobilien-Portfolio: {{count}}",
        },
        requestedValuation: {
            title: "Angeforderte Bewertung",
        },
    },
    en: {
        activeOffers: {
            footer: {
                apply: "Apply",
                selected: "Selected: {{count}}",
            },
            title: "Offers: {{count}}",
        },
        offerHistory: {
            title: "Offer history",
            noExistingOfferHistory: "No existing offer history.",
        },
        propertyPortfolio: {
            title: "Property portfolio: {{count}}",
        },
        requestedValuation: {
            title: "Requested Valuation",
        },
    },
};
