import { Button } from "@chakra-ui/react";
import { EnquiryType } from "../../../../types";
import { useEnquirySetDormant } from "../../../../utils/api/hooks";
import { useSmartTranslation } from "../../common/hooks/useSmartTranslation";

type ParamsType = {
    enquiry: EnquiryType;
    dormantForUser: boolean;
    userIsClient: boolean;
    onSuccess?: (data?: any) => void;
    onError?: (error: Error) => void;
};

const ArchiveEnquiry = (params: ParamsType) => {
    const {
        enquiry: { id, status },
        dormantForUser,
        userIsClient,
        onSuccess,
        onError,
    } = params;

    const t = useSmartTranslation();

    const { update: archiveEnquiry } = useEnquirySetDormant({
        onSuccess,
        onError,
    });

    const canArchive = userIsClient
        ? status === "CANCELLED" && !dormantForUser
        : !dormantForUser;

    return (
        <Button
            isDisabled={!canArchive}
            onClick={() =>
                archiveEnquiry({
                    dormant: true,
                    enquiryId: id,
                })
            }
        >
            {t("domain.enquiry.ACTIVITIES.archive.NAME")}
        </Button>
    );
};

export default ArchiveEnquiry;
