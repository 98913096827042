export type StandardSpecialValuationType =
    (typeof specialValuationTypes)[number];

const specialValuationTypes = [
    "Asbestos",
    "Drainage",
    "Electric",
    "ESG",
    "Gas & Central Heating",
    "Roof",
    "Structural",
    "Trees",
    "Wood and Damp",
    "Wall Anchors",
    "Wall Cavities",
    "Invasive Plants",
    "Refurbishment",
    "Sanitary",
] as const;

export default specialValuationTypes;
