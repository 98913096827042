import { getDaysUntilDate } from "../../../../common/functions";
import { displayDateWithCurrentLocale } from "../../../../common/functions/displayDateInLocale";
import { CaseType } from "../../../../types";
import { TranslationFunction } from "../../common/hooks/useSmartTranslation";

const T_NAMESPACE = "domain.case";

type AgreedDeliveryDate = {
    deliveryDate: string;
    daysToDeliveryDate: number;
    daysToDeliveryDateText: string;
};

export const getAgreedDeliveryDate = (
    caseData: CaseType,
    t: TranslationFunction
): AgreedDeliveryDate => {
    const { availability, timeline } = caseData.acceptedOffer;

    const availabilityDate = new Date(availability);
    const deliveryDate = new Date(availabilityDate);

    deliveryDate.setDate(availabilityDate.getDate() + Number(timeline));

    const deliveryDateInLocale = displayDateWithCurrentLocale(deliveryDate);
    const daysToDeliveryDate = getDaysUntilDate(deliveryDate);

    let daysToDeliveryDateText: string = t(`${T_NAMESPACE}.daysLeft`, {
        count: daysToDeliveryDate,
    });

    if (daysToDeliveryDate < 0) {
        daysToDeliveryDateText = t(`${T_NAMESPACE}.datePassed`);
    }

    return {
        deliveryDate: deliveryDateInLocale,
        daysToDeliveryDate,
        daysToDeliveryDateText,
    };
};
