import { ReactNode } from "react";
import { OfferType } from "../../../../../../types";
import DataTable from "../../../../common/components/data-table/DataTable";
import { enrichedOfferColumns } from "../../../../common/features/enrichment/domain/offer/display/EnrichedOfferColumns";
import { earliestAvailability } from "../../../../common/features/enrichment/domain/offer/enrichers/Availability";
import { bestSecurity } from "../../../../common/features/enrichment/domain/offer/enrichers/BestSecurity";
import { earliestDelivery } from "../../../../common/features/enrichment/domain/offer/enrichers/Delivery";
import { lowestPrice } from "../../../../common/features/enrichment/domain/offer/enrichers/Price";
import { shortestTimeline } from "../../../../common/features/enrichment/domain/offer/enrichers/ShortestTimeline";
import {
    enrichData,
    EnrichedDataType
} from "../../../../common/features/enrichment/enricher";

type OfferTableProps = {
    offers: OfferType[];
    checkedOffers: OfferType[];
    toggleOfferChecked: (offer: OfferType, checked: boolean) => void;
    footerContent?: ReactNode | ReactNode[];
};

const EnrichedOfferTable = (props: OfferTableProps) => {
    const { offers, checkedOffers, toggleOfferChecked, footerContent } = props;

    const enrichedOffers = enrichData(offers, [
        lowestPrice,
        bestSecurity,
        earliestAvailability,
        earliestDelivery,
        shortestTimeline,
    ]);

    const toggleEnrichedOfferChecked = (
        offer: EnrichedDataType<OfferType>,
        check: boolean
    ) => toggleOfferChecked(offer.data, check);

    const checkedEnrichedOffers = enrichedOffers.filter(({ data }) =>
        checkedOffers.includes(data)
    );

    return (
        <>
            <DataTable
                data={enrichedOffers}
                checkedRows={checkedEnrichedOffers}
                onToggleRowChecked={toggleEnrichedOfferChecked}
                columns={[
                    enrichedOfferColumns.valuerProfile,
                    enrichedOfferColumns.commment,
                    enrichedOfferColumns.piCoverValue,
                    enrichedOfferColumns.piCoverPercentage,
                    enrichedOfferColumns.specialPi,
                    enrichedOfferColumns.earliestAvailability,
                    enrichedOfferColumns.workingTime,
                    enrichedOfferColumns.delivery,
                    enrichedOfferColumns.price,
                    enrichedOfferColumns.view,
                    enrichedOfferColumns.deleteOffer,
                ]}
                footerContent={footerContent}
            />
        </>
    );
};

export { EnrichedOfferTable };

