export type StandardSystemStandardsOffered =
    (typeof standardSystemStandardsOffered)[number];

/*
- if the client doesn't get to see a choice for an enquiry standard, it remains the empty string
- "Other" is an option in the UI to indicate that a non-system-standard is requested,
  which is entered manually into a different field
*/
export type StandardSystemStandardsOfferedWithOther =
    | StandardSystemStandardsOffered
    | "Other"
    | "";

const standardSystemStandardsOffered = [
    "RICS - Red Book",
    "Tegova - Blue Book",
    "IVSC - White Book",
    "HypZert",
    "ValueV",
    "BDSF",
    "DESAG",
    "TAF",
    "AI",
    "ASA",
    "DIN 17024",
] as const;

export default standardSystemStandardsOffered;
