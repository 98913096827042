import { useState } from "react";
import { PropertyType } from "../../../../types";
import { propertyColumns } from "../../common/components/data-table/columns/PropertyColumns";
import DataTable from "../../common/components/data-table/DataTable";

type PortfolioTableProps = {
    properties: PropertyType[];
    selectedProperty?: PropertyType;
    onRowClick?: (property: PropertyType) => void;
};

const PortfolioTable = (props: PortfolioTableProps) => {
    const { properties, selectedProperty, onRowClick } = props;
    const columns = [
        propertyColumns.propertyId,
        propertyColumns.propertyAddress,
        propertyColumns.propertyType,
        propertyColumns.area,
        propertyColumns.estimateValue,
    ];

    return (
        // Add variant simple
        <DataTable
            data={properties}
            columns={columns}
            selectedRows={selectedProperty && [selectedProperty]}
            onRowClick={(property) => onRowClick?.(property)}
        />
    );
};

PortfolioTable.defaultProps = {
    onRowClick: undefined,
    selectedProperty: undefined,
};

export default PortfolioTable;
