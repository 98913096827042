export type StandardYearsOfExperience =
    (typeof standardYearsOfExperience)[number]["value"];

const standardYearsOfExperience = [
    { label: "lessThanOne", value: "0" },
    { label: "oneToThree", value: "1" },
    { label: "threeToFive", value: "3" },
    { label: "fiveOrMore", value: "5" },
] as const;

export default standardYearsOfExperience;
