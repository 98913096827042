import { offerStatusTranslations } from "./offerStatus";

export const translations = {
    de: {
        ACTIVITIES: {
            reject: {
                NAME: "Ablehnen",
                confirmationBlurb: "Sind Sie sicher, dass Sie dieses Angebot ablehnen möchten? Diese Aktion kann nicht rückgängig gemacht werden.",
            },
            rejectAll: {
                NAME: "Ablehnen",
                confirmationBlurb: "Diese Angebote wirklich ablehnen?",
            },
        },
        awaitingOffers: {
            waitingForOffers: "Warte auf Angebote...",
            waitingForOffersBlurb1:
                "Wenn Angebote von den Gutachtern eingereicht werden, sehen Sie sie hier.",
            waitingForOffersBlurb2:
                "Wir benachrichtigen Sie auch per E-Mail und Benachrichtigungssystem.",
        },
        offers: "Angebote",
        submit: "Abgeben",
        status: offerStatusTranslations.de,
    },
    en: {
        ACTIVITIES: {
            reject: {
                NAME: "Reject",
                confirmationBlurb: "Are you sure you want to reject this offer? This action cannot be undone.",
            },
            rejectAll: {
                NAME: "Reject",
                confirmationBlurb: "Reject all these offers?",
            },
        },
        awaitingOffers: {
            waitingForOffers: "Waiting for offers...",
            waitingForOffersBlurb1:
                "When any offers are submited by the valuers you will see them here.",
            waitingForOffersBlurb2:
                "We will also notify you by email and notification system.",
        },
        offers: "Offers",
        submit: "Submit",
        status: offerStatusTranslations.en,
    },
};
