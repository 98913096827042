import { HStack, IconButton, Text, VStack } from "@chakra-ui/react";
import { JSXElementConstructor, ReactElement } from "react";
import { EnquiryType } from "../../../../../../types";
import ProfilePicture from "../../../../common/components/display/ProfilePicture";
import { useSmartTranslation } from "../../../../common/hooks/useSmartTranslation";
import { Email, Fax, Mobile } from "../../../../common/icons/Icons";
import { Enquiry } from "../../../../domain";

type Props = {
    enquiry: EnquiryType;
    dormantForUser: boolean;
    userIsClient: boolean;
    onUpdate: () => any;
    onError: (error: Error) => any;
};

export const ValuerEnquiryTitle = (props: Props) => {
    const { enquiry, dormantForUser, userIsClient, onUpdate, onError } = props;

    return (
        <Enquiry.Title
            enquiry={enquiry}
            dormantForUser={dormantForUser}
            userIsClient={userIsClient}
            onUpdate={onUpdate}
            onError={onError}
            clientInfo={<ObscuredClient />}
        />
    );
};

const ObscuredClient = () => {
    const t = useSmartTranslation();

    return (
        <HStack>
            <ProfilePicture />
            <VStack
                align={"flex-start"}
                spacing="0"
            >
                <Text fontWeight="bold">
                    {t("domain.client.obscuredClient")}
                </Text>
                <Text variant="secondary">
                    {t(
                        "screens.platform.valuer.valuerEnquiryDetails.enquiryTitle.detailsAvailableAfterOfferAcceptance"
                    )}
                </Text>
            </VStack>
            <ConnectButton
                ariaLabel="mobile"
                icon={<Mobile />}
            />
            <ConnectButton
                ariaLabel="email"
                icon={<Email />}
            />
            <ConnectButton
                ariaLabel="fax"
                icon={<Fax />}
            />
        </HStack>
    );
};

//helpers
type ConnectButtonType = {
    icon: ReactElement<any, string | JSXElementConstructor<any>>;
    ariaLabel: string;
};
const ConnectButton = (props: ConnectButtonType) => {
    const { ariaLabel, icon } = props;
    return (
        <IconButton
            aria-label={ariaLabel}
            icon={icon}
            color="gray.400"
            bg="gray.50"
            borderRadius={30}
        />
    );
};
