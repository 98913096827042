import { StandardSpecialValuationType } from "../../../../../common/vars/valuationsAndMembershipTypes/standardSpecialValuationTypes";

type TranslationNSObject = {
    [key in StandardSpecialValuationType]: string;
};

type Translations = {
    de: TranslationNSObject;
    en: TranslationNSObject;
};

export const specialValuationTypeTranslations: Translations = {
    de: {
        Asbestos: "Asbest",
        Drainage: "Entwässerung",
        Electric: "Elektrisch",
        ESG: "ESG",
        "Gas & Central Heating": "Gas- & Zentralheizung",
        "Invasive Plants": "Invasive Pflanzen",
        Refurbishment: "Renovierung",
        Roof: "Dach",
        Sanitary: "Sanitär",
        Structural: "Strukturell",
        Trees: "Bäume",
        "Wall Anchors": "Wandverankerungen",
        "Wall Cavities": "Wandhohlräume",
        "Wood and Damp": "Holz und Feuchte",
    },
    en: {
        Asbestos: "Asbestos",
        Drainage: "Drainage",
        Electric: "Electric",
        ESG: "ESG",
        "Gas & Central Heating": "Gas & Central Heating",
        "Invasive Plants": "Invasive Plants",
        Refurbishment: "Refurbishment",
        Roof: "Roof",
        Sanitary: "Sanitary",
        Structural: "Structural",
        Trees: "Trees",
        "Wall Anchors": "Wall Anchors",
        "Wall Cavities": "Wall Cavities",
        "Wood and Damp": "Wood and Damp",
    },
};
