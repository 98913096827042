import { getDaysUntilDate } from "../../../../common/functions";
import { displayDateWithCurrentLocale } from "../../../../common/functions/displayDateInLocale";
import { descending } from "../../../../common/functions/sortNumbers";
import { EnquiryType } from "../../../../types";
import { TranslationFunction } from "../../common/hooks/useSmartTranslation";
import { NO_DATA } from "../../common/NO_DATA";

const T_NAMESPACE = "domain.enquiry";

const getDaysUntilDeliveryDate = (enquiry: EnquiryType) => {
    const deliveryDate = new Date(enquiry.completionDate);

    const daysCount = getDaysUntilDate(deliveryDate);

    return daysCount;
};

/**
 * @typedef {Object} getDeliveryDateReturn
 * @property {string} deliveryDate - The locale formatted delivery date
 * @property {string} daysToDeliveryDateText - Translated text as "{days} days left" or "Date passed"
 * @property {number} daysToDeliveryDate - Number of days until delivery
 */

/**
 *
 * @returns {getDeliveryDateReturn}
 */
export const getDeliveryDate = (
    enquiry: EnquiryType,
    t: TranslationFunction
) => {
    const deliveryDate = displayDateWithCurrentLocale(enquiry.completionDate);
    const daysToDeliveryDate = getDaysUntilDeliveryDate(enquiry);

    let daysToDeliveryDateText: string = t(`${T_NAMESPACE}.daysLeft`, {
        count: daysToDeliveryDate,
    });

    if (daysToDeliveryDate < 0) {
        daysToDeliveryDateText = t(`${T_NAMESPACE}.datePassed`);
    }

    return {
        deliveryDate,
        daysToDeliveryDate,
        daysToDeliveryDateText,
    };
};

export const calculateRequiredAbsolutePICover = (enquiry: EnquiryType) => {
    const { properties, valuerMinimumPICoverPercentage } = enquiry;

    const highestPropertyPrice = properties
        .map((property) => property.estimateValue)
        .sort(descending)[0];

    return (highestPropertyPrice * valuerMinimumPICoverPercentage) / 100;
};

export const determineType = (enquiry: EnquiryType, t: TranslationFunction) => {
    const type = t(`domain.standards.valuationType.${enquiry.valuationType}`);

    const otherType =
        enquiry.valuationType === "Other Valuation" && enquiry.valuationOther;

    return {
        type,
        otherType,
    };
};

export const determineStandard = (
    enquiry: EnquiryType,
    t: TranslationFunction
) => {
    // private client: no choice provided
    if (enquiry.valuationStandards === "") return NO_DATA;

    // system standard
    if (enquiry.valuationStandards !== "Other")
        return t(
            `domain.standards.systemStandard.${enquiry.valuationStandards}`
        );

    // other standard: manual input
    return enquiry.valuationStandardsOther;
};
