import { OfferType } from "../../../../types";
import { offerColumns } from "./OfferColumns";
import DataTable from "../../common/components/data-table/DataTable";
import { useSmartTranslation } from "../../common/hooks/useSmartTranslation";

type OfferHistoryTable = {
    offers: OfferType[];
};

const OfferHistoryTable = (props: OfferHistoryTable) => {
    const { offers } = props;
    const t = useSmartTranslation();

    return (
        <>
            <DataTable
                data={offers}
                columns={[
                    offerColumns.valuerProfile,
                    offerColumns.piCoverValue,
                    offerColumns.piCoverPercentage,
                    offerColumns.specialPi,
                    offerColumns.earliestAvailability,
                    offerColumns.workingTime,
                    offerColumns.delivery,
                    offerColumns.price,
                    offerColumns.view,
                ]}
                noDataText={t("clientEnquiryDetails.offerHistory.noExistingOfferHistory")}                
            />
        </>
    );
};

export { OfferHistoryTable };
