// Enrichment types for enrichment functions
export type Highlight = {
    keyword: string;
};

export type Lowlight = {
    keyword: string;
};

export type HighlightedData<T> = {
    data: T;
    highlight: Highlight;
};

export type LowlightedData<T> = {
    data: T;
    lowlight: Lowlight;
};

export type Enrichment<T> = {
    fieldName: string;
    highlightedData?: HighlightedData<T>[];
    lowlightedData?: LowlightedData<T>[];
};

export type Enricher<T> = (data: T[]) => Enrichment<T>;

export type EnrichedDataType<T> = {
    data: T;
    highlights: {
        [index: string]: Highlight[];
    };
    lowlights: {
        [index: string]: Lowlight[];
    };
};

const enrichData = <T>(data: T[], enrichers: Enricher<T>[]) => {
    const enriched: EnrichedDataType<T>[] = data.map((d) => ({
        data: d,
        highlights: {},
        lowlights: {},
    }));

    enrichers.forEach((enricher) => {
        const { fieldName, highlightedData, lowlightedData } = enricher(data);

        if (highlightedData)
            highlightedData.forEach((ho) => {
                const { data, highlight } = ho;

                const enrichedOffer = enriched.find((e) => e.data === data)!;

                if (!enrichedOffer.highlights[fieldName])
                    enrichedOffer.highlights[fieldName] = [];

                enrichedOffer.highlights[fieldName].push(highlight);
            });

        if (lowlightedData)
            lowlightedData.forEach((lo) => {
                const { data, lowlight } = lo;

                const enrichedOffer = enriched.find((e) => e.data === data)!;

                if (!enrichedOffer.lowlights[fieldName])
                    enrichedOffer.lowlights[fieldName] = [];

                enrichedOffer.lowlights[fieldName].push(lowlight);
            });
    });

    return enriched;
};

export { enrichData };
