import { VStack, Text, IconButton } from '@chakra-ui/react';
import { useSmartTranslation } from '../../hooks/useSmartTranslation';
import { Lock1 } from 'iconsax-react';


const AwaitingAccessBanner = () => {
    const t = useSmartTranslation();

    return (
        <VStack
            w="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            padding="2rem"
            bg="white"
            borderRadius={8}
            spacing={2}
        >
            <IconButton
                icon={<Lock1 size="md" />}
                aria-label="clock"
                color="gray.500"
                bg="white"
            />

            <Text color="gray.500" textAlign="center">
                {t('awaitingAccessBanner.awaitingApprovalBlurbOne')}
            </Text>

            <Text color="gray.500" textAlign="center">
                {t('awaitingAccessBanner.awaitingApprovalBlurbTwo')}
            </Text>
        </VStack>
    );
};

export default AwaitingAccessBanner;