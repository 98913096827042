export const caseColumns = {
    de: {
        case: "Fall",
        client: "Kunde",
        expectedDelivery: "Fällig",
        noOfProperties: "Anzahl Immobilien: {{count}}",
        portfolioValue: "Geschätzter Portfoliowert",
        position: {
            at: "{{position}} bei {{company}}",
        },
        piCover: "PI Cover",
        selectedValuer: "Gewählter Gutachter",
        type: "Bewertungsart",
        moreText: "+{{count}} weitere",
    },
    en: {
        case: "Case",
        client: "Client",
        expectedDelivery: "Expected delivery",
        noOfProperties: "No. of properties: {{count}}",
        portfolioValue: "Est. portfolio value",
        position: {
            at: "{{position}} at {{company}}",
        },
        piCover: "PI Cover",
        selectedValuer: "Selected valuer",
        type: "Type",
        moreText: "+ {{count}} more",
    },
};

export const enquiryColumns = {
    de: {
        activeOffers: "Aktive Angebote",
        bestDelivery: "Früheste Lieferung",
        bestOffer: "Günstigstes Angebot",
        delivery: "Fällig:",
        enquiry: "Anfrage",
        expectedDelivery: "Fällig",
        moreText: "+{{count}} weitere",
        myOffer: "Mein Angebot",
        newText: "+{{count}} neue",
        piCover: "PI Cover",
        portfolioValue: "Geschätzter Portfoliowert",
        position: {
            at: "{{position}} bei {{company}}",
        },
        price: "Preis:",
        type: "Bewertungsart",
    },
    en: {
        activeOffers: "Active offers",
        bestDelivery: "Best delivery",
        bestOffer: "Best offer",
        delivery: "Delivery:",
        enquiry: "Enquiry",
        expectedDelivery: "Expected Delivery",
        moreText: "+{{count}} more",
        myOffer: "My offer",
        newText: "+{{count}} new",
        piCover: "PI Cover",
        portfolioValue: "Est. portfolio value",
        position: {
            at: "{{position}} at {{company}}",
        },
        price: "Price:",
        type: "Type",
    },
};

export const propertyColumns = {
    de: {
        address: "Adresse",
        propertyType: "Art",
        area: "Fläche",
        estimateValue: "Geschätzter Wert",
        moreText: "+ {{number}} weitere",
    },
    en: {
        address: "Address",
        propertyType: "Property type",
        area: "Area",
        estimateValue: "Estimated value",
        moreText: "+ {{number}} more",
    },
};

export const offerColumns = {
    de: {
        check: "Anzeigen",
        days: "Tage",
        hasCommentTooltip: "Der Gutachter hat zusätzliche Informationen bereitgestellt",
        piCoverValue: "PI Cover[€]",
        piCoverPercentage: "[%]",
        specialPi: "Sonderkonditionen PI",
        earliestAvailability: "Früheste Verfügbarkeit",
        workingTime: "Bearbeitungszeit",
        delivery: "Fällig",
        price: "Preis",
        valuer: "Gutachter",
    },
    en: {
        check: "Check",
        days: "Days",
        hasCommentTooltip: "The valuer has provided additional information",
        piCoverValue: "PI Cover[€]",
        piCoverPercentage: "[%]",
        specialPi: "Special PI",
        earliestAvailability: "Earliest Avail.",
        workingTime: "Working Time",
        delivery: "Delivery",
        price: "Price",
        valuer: "Valuer",
    },
};
