import { Box, Flex, Heading, IconButton, Text } from "@chakra-ui/react";
import { ArrowRight2 } from "iconsax-react";
import { Link as ReactLink } from "react-router-dom";
import delimitNumber from "../../../../../../common/functions/delimitNumber";
import { displayDateWithCurrentLocale } from "../../../../../../common/functions/displayDateInLocale";
import { EnquiryType, OfferOnlyType } from "../../../../../../types";
import { Enquiry, Offer } from "../../../../domain";
import { TranslationFunction } from "../../../hooks/useSmartTranslation";
import { DataTableColumnType } from "../DataTable";
import {
    calculateBestDelivery,
    calculateBestOffer,
    estimatePortfolioValue,
    filterActiveOffers,
} from "../TableDataCalculations";

type Column = DataTableColumnType<
    EnquiryType & {
        offers: OfferOnlyType[];
    }
>;

const translationNameSpace =
    "common.components.dataTable.columns.enquiryColumns";

export const enquiryIdWithProperties: Column = {
    title: (t) => t(`${translationNameSpace}.enquiry`),
    render: (i, t) => {
        const firstProperty = `${i.properties[0].city}, ${i.properties[0].street}`;
        const noOfProperties = i.properties.length;

        return (
            <Flex
                direction="column"
                gap={0}
                data-testid="enquiry-id"
            >
                <Text
                    color="gray.900"
                    fontWeight={700}
                >
                    {i.id}
                </Text>
                <Text
                    color="gray.500"
                    fontSize={14}
                >
                    {firstProperty}
                </Text>
                {noOfProperties > 1 ? (
                    <Text
                        color="gray.500"
                        fontSize={14}
                    >
                        {t(`${translationNameSpace}.moreText`, {
                            count: noOfProperties - 1,
                        })}
                    </Text>
                ) : (
                    ""
                )}
            </Flex>
        );
    },
};

export const valuationRequirements: Column = {
    title: (t) => t(`${translationNameSpace}.type`),
    render: (i, t) => {
        const { type, otherType } =
            Enquiry.determineType(i, t);
        
        const standard = Enquiry.determineStandard(i, t);

        return (
            <Flex direction="column">
                <Heading size="sm">{type}</Heading>
                <Text variant="secondary">{otherType}</Text>
                <Text variant="secondary">{standard}</Text>
            </Flex>
        );
    },
};

export const piCoverRequirements: Column = {
    title: (t) => t(`${translationNameSpace}.piCover`),
    render: (i) => {
        const coverValue = `€${delimitNumber(i.valuerMinimumPICover)}`;

        return (
            <Flex direction="column">
                <Heading size="sm">{coverValue}</Heading>
                <Text variant="secondary">{`${i.valuerMinimumPICoverPercentage}%`}</Text>
            </Flex>
        );
    },
};

export const expectedDeliveryDate: Column = {
    alignment: "right",
    title: (t) => t(`${translationNameSpace}.expectedDelivery`),
    render: (i, t) => {
        const { deliveryDate, daysToDeliveryDateText } =
            Enquiry.getDeliveryDate(i, t);

        return (
            <Flex
                alignItems="end"
                direction="column"
            >
                <Heading size="sm">{deliveryDate}</Heading>
                <Text variant="secondary">{daysToDeliveryDateText}</Text>
            </Flex>
        );
    },
    sorter: (a, b) =>
        new Date(a.completionDate).getTime() -
        new Date(b.completionDate).getTime(),
};

export const estPortfolioValueEnquiry: Column = {
    alignment: "right",
    title: (t: TranslationFunction) =>
        t(`${translationNameSpace}.portfolioValue`),
    render: (i) => {
        const { properties } = i;
        const estPortfolioValue = estimatePortfolioValue(properties);

        const delimitedValue = `€${delimitNumber(estPortfolioValue)}`;

        return (
            <Heading
                size="sm"
                color="cyan.500"
            >
                {delimitedValue}
            </Heading>
        );
    },
    sorter: (a, b) => {
        const aEstPortfolioValue = estimatePortfolioValue(a.properties);
        const bEstPortfolioValue = estimatePortfolioValue(b.properties);

        return aEstPortfolioValue - bEstPortfolioValue;
    },
};

export const offers: Column = {
    title: (t) => t(`domain.offer.offers`),
    render: (i) =>
        i.offers ? (
            <Heading size="sm">{i.offers.length}</Heading>
        ) : (
            <Text></Text>
        ),
};

export const activeOffers: Column = {
    alignment: "center",
    title: (t) => t(`${translationNameSpace}.activeOffers`),
    render: (i, t) => {
        const activeOffers = filterActiveOffers(i.offers);

        if (!i.offers.length) {
            return (
                <Text
                    fontWeight={700}
                    color="gray.400"
                    data-testid="active-offers"
                >
                    {t("common.n/a")}
                </Text>
            );
        }

        return (
            <Flex
                direction="column"
                align="center"
                gap={1}
                data-testid="active-offers"
            >
                <Text
                    fontWeight={700}
                    color="gray.900"
                >
                    {activeOffers}
                </Text>
                {/* // TODO: Not sure about that, how to track status. @Christy */}
                <Box
                    bg="green.500"
                    borderRadius="2"
                    color="white"
                    fontWeight="bold"
                    paddingInline={1}
                    fontSize={12}
                    textAlign="center"
                    textTransform="uppercase"
                >
                    {t(`${translationNameSpace}.newText`, { count: 1 })}
                </Box>
            </Flex>
        );
    },
};

export const bestOffer: Column = {
    title: (t) => t(`${translationNameSpace}.bestOffer`),
    render: (i, t) => {
        if (!i.offers.length) {
            return (
                <Text
                    fontWeight={700}
                    color="gray.400"
                    data-testid="best-offer"
                >
                    {t("common.n/a")}
                </Text>
            );
        }

        const bestOffer = calculateBestOffer(i.offers);

        let bestOfferDeliveryDate = "";

        if (bestOffer) {
            bestOfferDeliveryDate = displayDateWithCurrentLocale(
                Offer.calculateDeliveryDate(bestOffer)
            );
        }

        return (
            <Flex
                direction="column"
                gap={1}
                data-testid="best-offer"
            >
                <Text
                    color="green.500"
                    fontWeight={700}
                >
                    €{bestOffer?.price}
                </Text>
                <Text
                    color="gray.500"
                    fontSize={12}
                >
                    {t(`${translationNameSpace}.delivery`)}{" "}
                    {bestOfferDeliveryDate}
                </Text>
            </Flex>
        );
    },
};

export const bestDelivery: Column = {
    title: (t) => t(`${translationNameSpace}.bestDelivery`),
    render: (i, t) => {
        if (!i.offers.length) {
            return (
                <Text
                    fontWeight={700}
                    color="gray.400"
                    data-testid="best-delivery"
                >
                    {t("common.n/a")}
                </Text>
            );
        }

        const bestDelivery = calculateBestDelivery(i.offers);

        let bestDeliveryDate = "";

        if (bestDelivery) {
            bestDeliveryDate = displayDateWithCurrentLocale(
                Offer.calculateDeliveryDate(bestDelivery)
            );
        }

        return (
            <Flex
                direction="column"
                gap={1}
                data-testid="best-delivery"
            >
                <Text
                    color="gray.900"
                    fontWeight={700}
                >
                    {bestDeliveryDate}
                </Text>
                <Text
                    color="gray.500"
                    fontSize={12}
                >
                    {t(`${translationNameSpace}.price`)} €{bestDelivery?.price}
                </Text>
            </Flex>
        );
    },
};

export const viewClientEnquiry: Column = {
    render: (i: EnquiryType) => {
        return (
            <IconButton
                as={ReactLink}
                to={`/dashboard/enquiry/${i.id}`}
                icon={<ArrowRight2 />}
                variant="ghost"
                color="gray.500"
                aria-label="arrow"
                data-testid="view-enquiry"
            />
        );
    },
};

export const enquiryColumns: { [index: string]: Column } = {
    enquiryIdWithProperties,
    valuationRequirements,
    piCoverRequirements,
    offers,
    expectedDeliveryDate,
    estPortfolioValueEnquiry,
    activeOffers,
    bestOffer,
    bestDelivery,
    viewClientEnquiry,
};
