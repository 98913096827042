import { Button } from "@chakra-ui/button";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { Text } from "@chakra-ui/layout";
import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/menu";
import { Clipboard, Link1 } from "iconsax-react";
import { useNavigate } from "react-router";
import { EntityType } from "../../../../../../types";
import { useSmartTranslation } from "../../../../common/hooks/useSmartTranslation";

type PropsType = {
    valuerEntity: EntityType;
};

const CreateEnquiryButton = (props: PropsType) => {
    const { valuerEntity } = props;

    const t = useSmartTranslation();

    const navigate = useNavigate();

    return (
        <Menu>
            <MenuButton
                as={Button}
                variant="primary"
                width="12.8rem"
                rightIcon={<ChevronDownIcon />}
            >
                {t("domain.enquiry.createEnquiry")}
            </MenuButton>

            <MenuList>
                <MenuItem
                    paddingBlock=".8rem"
                    onClick={() =>
                        navigate(
                            `/direct-enquiry-form/individual/${valuerEntity.identityToken}`
                        )
                    }
                >
                    <Clipboard size="1.1rem" />
                    <Text paddingInline=".5rem">
                        {t("domain.client.privateClient")}
                    </Text>
                </MenuItem>

                <MenuItem
                    paddingBlock=".8rem"
                    onClick={() =>
                        navigate(
                            `/direct-enquiry-form/company/${valuerEntity.identityToken}`
                        )
                    }
                >
                    <Clipboard size="1.1rem" />
                    <Text paddingInline=".5rem">
                        {t("domain.client.institutionalClient")}
                    </Text>
                </MenuItem>

                <MenuItem
                    paddingBlock=".8rem"
                    onClick={() => navigate("/dashboard/account/integration")}
                >
                    <Link1 size="1.1rem" />
                    <Text paddingInline=".5rem">
                        {t("menubar.createEnquiry.sendLink")}
                    </Text>
                </MenuItem>
            </MenuList>
        </Menu>
    );
};

export default CreateEnquiryButton;
