import { useDisclosure } from "@chakra-ui/react";
import { useMutation } from "react-query";
import { EnquiryType } from "../../../../types";
import { enquiryCancel } from "../../../../utils/api/endpoints";
import ConfirmationModal from "../../common/components/ConfirmationModal";
import { useSmartTranslation } from "../../common/hooks/useSmartTranslation";

type ParamsType = {
    onSuccess?: (data?: any) => void;
    onError?: (error: Error) => void;
    enquiry: EnquiryType;
};

const useEnquiryCancel = (params: ParamsType) => {
    const {
        enquiry: { id },
        onSuccess,
        onError,
    } = params;

    const t = useSmartTranslation();

    const { isOpen, onOpen, onClose } = useDisclosure();

    const { mutate: cancelEnquiry } = useMutation(
        async (enquiryId: number) => enquiryCancel(enquiryId),
        {
            onSuccess,
            onError,
        }
    );

    const onConfirmCancellation = () => {
        onClose();
        cancelEnquiry(id);
    };

    const confirmationModal = (
        <ConfirmationModal
            isOpen={isOpen}
            onConfirmation={onConfirmCancellation}
            onClose={onClose}
            title={t("domain.enquiry.ACTIVITIES.cancel.NAME")}
            blurb={t("domain.enquiry.ACTIVITIES.cancel.confirmationBlurb")}
            confirmationButtonLabel={t("domain.enquiry.ACTIVITIES.cancel.NAME")}
            confirmationButtonVariant={"secondary"}
        />
    );

    return {
        cancelEnquiry: onOpen,
        confirmationModal,
    };
};

export default useEnquiryCancel;
