import { OfferType } from "../../../../../../../../types";
import { Enricher } from "../../../enricher";
import { CHEAPEST } from "./enrichers";

export const lowestPrice: Enricher<OfferType> = (offers: OfferType[]) => {
    const priceLowToHigh = [...offers].sort((o1, o2) => {
        const price1 = o1.price;
        const price2 = o2.price;

        if (price1 < price2) return -1;
        if (price1 > price2) return 1;
        return 0;
    });

    const highlightedData = priceLowToHigh
        .filter((o) => o.price === priceLowToHigh[0].price)
        .map((o) => ({
            data: o,
            highlight: {
                keyword: CHEAPEST,
            },
        }));

    return {
        fieldName: "price",
        highlightedData,
    };
};
